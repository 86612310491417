/* You can add global styles to this file, and also import other style files */
@import '~quill/dist/quill.snow.css';

.ql-editor em {
  font-style: italic;
}

.credential img::before,
app-credential-detail img::before {
  content: "No Image";
  color: #fff;
  background-color: #a9a9a9;
  display: block;
  position: absolute;
  z-index: 1;
  text-align: center;
  vertical-align: middle;
}
app-credential-detail .l-overview-x-image img::before {
  width: 160px;
  height: 160px;
  line-height: 160px;
}
app-credential-detail img[height="64"]::before {
  font-size: 10px;
  width: 64px;
  height: 64px;
  line-height: 64px;
}
.credential img::before {
  width: 227px;
  height: 227px;
  line-height: 227px;
}
#credentials {
  max-width: 1080px;
  margin: 0 auto;
}
.credential {
  margin: 10px 0;
  cursor: pointer;
  padding: 10px;
  width: 250px;
  float: left;
  overflow: hidden;
  height: 300px;
  position: relative;
}
.credential:hover {
  background-color: #fafafa;
}
