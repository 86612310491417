[tabindex="-1"] {
  outline: 0; }

body,
button,
input,
select,
textarea {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  -webkit-appearance: none;
  appearance: none;
  background: none;
  footer: 0;
  border-radius: 0;
  color: #493e37;
  display: block;
  font-family: "museo-sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  padding: 0; }

html,
body {
  height: 100%;
  width: 100%; }

a,
abbr {
  text-decoration: none; }

body {
  background-color: #f7f7f7; }

button {
  border: none;
  cursor: pointer; }

hr {
  border: 0;
  margin: 0; }

main {
  display: block; }

strong {
  font-weight: bold; }

.l-containerhorizontal {
  padding-left: 16px;
  padding-right: 16px; }
  @media (min-width: 1032px) {
    .l-containerhorizontal {
      padding-left: calc(50vw - 500px);
      padding-right: calc(50vw - 500px); } }

.l-containerhorizontalmaxwidth {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px; }

.l-accordion {
  margin-bottom: 24px;
  margin-top: 16px; }
  .l-accordion > *:not(:last-child) {
    margin-bottom: 8px; }
  @media (min-width: 768px) {
    .l-accordion {
      margin-bottom: 8px; } }

.l-attachment:not(:last-of-type) {
  margin-bottom: 24px; }

.l-cardsdownload .l-cardsdownload-x-sectionhead {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px; }
  .l-cardsdownload .l-cardsdownload-x-sectionhead > * {
    margin-bottom: 8px; }

.l-cardsdownload .l-cardsdownload-x-select {
  max-width: 100%; }

.l-cardsdownload .l-cardsdownload-x-section {
  padding-top: 24px; }

@media (min-width: 768px) {
  .l-cardsdownload .l-cardsdownload-x-select {
    margin-bottom: 16px;
    max-width: 288px; } }

.l-cardsreview {
  margin-bottom: 16px;
  margin-top: 16px; }
  .l-cardsreview > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px; }
    .l-cardsreview > *:not(:last-child) {
      margin-bottom: 8px; }
  @media (min-width: 768px) {
    .l-cardsreview {
      margin-bottom: 32px;
      margin-top: 32px; }
      .l-cardsreview > * {
        max-width: none; } }

.l-childrenhorizontal {
  display: flex; }
  .l-childrenhorizontal > *:not(:last-child) {
    margin-right: 8px; }

.l-detail {
  padding-bottom: 64px; }
  .l-detail .l-detail-x-content {
    box-sizing: border-box;
    flex: 1 1 100%; }
  .l-detail .l-detail-x-nav {
    display: none;
    flex: 0 0 160px;
    padding-bottom: 16px;
    padding-top: 16px; }
  .l-detail .l-detail-x-sidebaritems {
    margin-bottom: 32px; }
  .l-detail .l-detail-x-sidebarbutton {
    margin-left: 16px; }
  .l-detail .l-detail-x-subsection {
    margin-bottom: 8px;
    padding: 8px 0 16px 0; }
    .l-detail .l-detail-x-subsection > * {
      padding: 0 16px; }
    .l-detail .l-detail-x-subsection > h2 {
      padding: 0 8px; }
    .l-detail .l-detail-x-subsection .l-detail-x-subsectionbig {
      margin-top: 16px; }
    .l-detail .l-detail-x-subsection .l-detail-x-subsectionnopadding {
      padding: 0; }
    .l-detail .l-detail-x-subsection .l-detail-x-headspacing > * {
      margin-bottom: 16px; }
  .l-detail .l-detail-x-subsectionsm > h1 {
    max-width: 640px; }
  .l-detail .l-detail-x-paddingsm {
    padding-bottom: 4px; }
  .l-detail .l-detail-x-button {
    margin-top: 24px; }
  @media (min-width: 435px) {
    .l-detail .l-detail-x-subsection > * {
      padding: 0 24px; }
    .l-detail .l-detail-x-subsection > h2 {
      padding: 0 16px; }
    .l-detail .l-detail-x-subsection .l-detail-x-subsectionbig {
      padding: 0 24px; } }
  @media (min-width: 768px) {
    .l-detail .l-detail-x-subsection {
      padding: 24px 0 32px 0; }
      .l-detail .l-detail-x-subsection > * {
        padding-right: 96px; }
    .l-detail .l-detail-x-paddingmd,
    .l-detail .l-detail-x-paddingsm {
      padding-bottom: 16px; } }
  @media (min-width: 784px) {
    .l-detail {
      display: flex; }
      .l-detail .l-detail-x-content {
        order: 2; }
      .l-detail .l-detail-x-subsection > * {
        padding: 0 32px; }
      .l-detail .l-detail-x-subsection > h2 {
        padding: 0 24px; }
      .l-detail .l-detail-x-subsection .l-detail-x-subsectionbig {
        padding: 0 32px; }
      .l-detail .l-detail-x-nav {
        display: block;
        margin-right: 8px;
        order: 1;
        position: sticky;
        position: -webkit-sticky;
        top: 0; } }
  @media (min-width: 1024px) {
    .l-detail .l-detail-x-nav {
      padding: 32px 0 32px 32px; }
    .l-detail .l-detail-x-sidebarbutton {
      margin-left: 0; }
    .l-detail .l-detail-x-subsection .l-detail-x-subsectionbig {
      padding: 0 16px; }
    .l-detail .l-detail-x-subsection .l-detail-x-subsectionmid {
      padding: 0 32px; } }
  @media (min-width: 1032px) {
    .l-detail {
      padding-left: calc(50vw - 500px);
      padding-right: calc(50vw - 500px); } }

.l-dialog {
  height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 4; }

.l-dialogform {
  padding: 16px; }
  .l-dialogform > *:not(:last-child) {
    margin-bottom: 16px; }
  .l-dialogform .l-dialogform-x-button {
    display: flex;
    flex-direction: row-reverse;
    text-align: right; }
    .l-dialogform .l-dialogform-x-button > *:not(:last-child) {
      margin-left: 8px; }
  .l-dialogform .l-dialogform-x-maininfo {
    margin-bottom: 16px; }
    .l-dialogform .l-dialogform-x-maininfo span {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px; }
  .l-dialogform .l-dialogform-x-note > *:not(:last-child) {
    margin-bottom: 16px; }
  .l-dialogform .l-dialogform-x-note a {
    color: #2578a2; }
  @media (min-width: 768px) {
    .l-dialogform {
      padding: 32px; } }

.l-dialogoverview {
  border-bottom: 1px solid #f1f2e7;
  display: flex;
  padding-bottom: 16px;
  position: relative; }
  .l-dialogoverview li:not(:last-child) {
    margin-bottom: 8px; }
  .l-dialogoverview .l-dialogoverview-x-header {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px; }
  .l-dialogoverview .l-dialogoverview-x-content {
    flex: 1 1 100%; }
  .l-dialogoverview .l-dialogoverview-x-image {
    height: 48px;
    margin-right: 16px;
    position: relative;
    width: 48px; }
    .l-dialogoverview .l-dialogoverview-x-image img {
      display: block;
      height: auto;
      width: 100%; }

.l-count {
  display: block;
  margin-bottom: 16px;
  padding-bottom: 12px;
  padding-top: 12px; }
  .l-count .l-count-x-dropdown > select {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    background: url("images/forminput-select.svg") calc(100% - 8px) center no-repeat #fff;
    border: 1px solid rgba(73, 62, 55, 0.1);
    border-radius: 500px;
    color: #2578a2;
    display: flex;
    outline: none;
    padding: 4px 32px 4px 8px;
    position: relative;
    width: 100%; }
  @media (min-width: 768px) {
    .l-count {
      display: none; } }

.l-filter {
  display: flex;
  margin-bottom: 16px;
  margin-top: 16px; }
  @media (min-width: 768px) {
    .l-filter {
      margin-top: 32px; } }
  @media (min-width: 1024px) {
    .l-filter {
      display: none; } }

.l-filter-big {
  margin-top: 0;
  padding-bottom: 12px;
  padding-top: 12px; }
  @media (min-width: 768px) {
    .l-filter-big {
      display: none; } }

.l-gridfour {
  margin-bottom: 24px;
  margin-top: 8px; }
  .l-gridfour > *:not(:last-child) {
    margin-bottom: 8px; }
  @media (min-width: 496px) {
    .l-gridfour {
      align-items: stretch;
      display: flex;
      flex-wrap: wrap; }
      .l-gridfour > *:not(:last-child) {
        margin-bottom: 0; }
      .l-gridfour > * {
        align-items: stretch;
        box-sizing: border-box;
        display: flex;
        padding-bottom: 16px;
        width: 50%; }
        .l-gridfour > *:nth-child(odd) {
          padding-right: 8px; }
        .l-gridfour > *:nth-child(even) {
          padding-left: 8px; } }
  @media (min-width: 768px) {
    .l-gridfour {
      margin-top: 16px; }
      .l-gridfour > * {
        width: 33.3333%; }
        .l-gridfour > *:nth-child(odd) {
          padding-right: 0; }
        .l-gridfour > *:nth-child(even) {
          padding-left: 0; }
        .l-gridfour > *:nth-child(3n+1) {
          padding-right: 8px; }
        .l-gridfour > *:nth-child(3n+2) {
          padding-left: 4px;
          padding-right: 4px; }
        .l-gridfour > *:nth-child(3n+3) {
          padding-left: 8px; } }
  @media (min-width: 1000px) {
    .l-gridfour > * {
      width: 25%; }
      .l-gridfour > *:nth-child(3n+1) {
        padding-right: 0; }
      .l-gridfour > *:nth-child(3n+2) {
        padding-left: 0;
        padding-right: 0; }
      .l-gridfour > *:nth-child(3n+3) {
        padding-left: 0; }
      .l-gridfour > *:nth-child(4n+1) {
        padding-right: 12px; }
      .l-gridfour > *:nth-child(4n+2) {
        padding-left: 4px;
        padding-right: 8px; }
      .l-gridfour > *:nth-child(4n+3) {
        padding-left: 8px;
        padding-right: 4px; }
      .l-gridfour > *:nth-child(4n+4) {
        padding-left: 12px; } }

.l-hidden {
  opacity: 0;
  margin-top: 16px;
  pointer-events: none;
  position: absolute;
  visibility: hidden;
  display: none; }

.l-iframe {
  height: 100%;
  width: 100%; }

.l-inertcontainer {
  height: 100%;
  width: 100%; }
  .l-inertcontainer[inert] {
    overflow: hidden;
    position: fixed; }

.l-marketinggridfour {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto 40px auto; }
  .l-marketinggridfour > * {
    width: 100%; }
  @media (min-width: 768px) {
    .l-marketinggridfour {
      max-width: 456px; }
      .l-marketinggridfour > * {
        width: 50%; } }
  @media (min-width: 1024px) {
    .l-marketinggridfour {
      max-width: 930px; }
      .l-marketinggridfour > * {
        width: 25%; } }

.l-overflowauto {
  overflow: auto; }

.l-overview {
  position: relative; }
  .l-overview h3 {
    font-weight: bold;
    margin-top: 24px; }
  .l-overview li:not(:last-child) {
    margin-bottom: 8px; }
  .l-overview p {
    margin-bottom: 8px; }
    .l-overview p:last-of-type {
      margin-bottom: 24px; }
    .l-overview p span {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px; }
  .l-overview .l-overview-x-calltoaction {
    margin-bottom: 16px; }
  .l-overview .l-overview-x-image {
    height: 288px;
    margin: 0 auto 16px auto;
    width: 288px; }
    .l-overview .l-overview-x-image img {
      display: block;
      height: auto;
      width: 100%; }
  .l-overview .l-overview-x-actions {
    display: flex;
    margin-bottom: 16px; }
    .l-overview .l-overview-x-actions .l-overview-x-socials {
      display: flex;
      margin-right: 4px; }
      .l-overview .l-overview-x-actions .l-overview-x-socials > *:not(:last-of-type) {
        margin-right: 2px; }
  .l-overview .l-overview-x-share h6,
  .l-overview .l-overview-x-share p {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px; }
  .l-overview .l-overview-x-share h6 {
    font-weight: bold; }
  @media (min-width: 768px) {
    .l-overview {
      display: flex; }
      .l-overview .l-overview-x-calltoaction {
        margin-bottom: 32px;
        padding-left: 0; }
      .l-overview .l-overview-x-content {
        flex: 0 0 360px; }
      .l-overview .l-overview-x-image {
        height: 240px;
        margin-left: 0;
        margin-right: 32px;
        position: relative;
        width: 240px; }
      .l-overview .l-overview-x-actions .l-overview-x-socials {
        margin-right: 8px; }
        .l-overview .l-overview-x-actions .l-overview-x-socials > *:not(:last-of-type) {
          margin-right: 4px; } }

.l-overview-big .l-overview-x-section:not(:last-of-type) {
  border-bottom: 1px solid #d9dacd;
  margin-bottom: 24px;
  padding-bottom: 24px; }

.l-overview-big .l-overview-x-section h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; }

.l-overview-big .l-overview-x-section p a {
  color: #2578a2;
  text-decoration: underline; }

.l-overview-award ul {
  margin-bottom: 16px; }

.l-overview-award p {
  margin-bottom: 16px; }
  .l-overview-award p:last-of-type {
    margin-bottom: 16px; }

.l-overview-award .l-overview-x-buttonicon > * {
  padding-left: 32px; }

.l-overview-award .l-overview-x-text {
  display: flex;
  flex-direction: column-reverse; }

@media (min-width: 768px) {
  .l-overview-award p:last-of-type {
    margin-bottom: 24px; }
  .l-overview-award .l-overview-x-text {
    display: block; } }

.l-paginationalt1 {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 24px auto; }
  .l-paginationalt1 .l-paginationalt1-x-pages {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    padding-left: 24px;
    padding-right: 24px; }
    .l-paginationalt1 .l-paginationalt1-x-pages > *:not(.l-paginationalt1-x-count) {
      display: none; }
  @media (min-width: 768px) {
    .l-paginationalt1 {
      margin-top: 0; }
      .l-paginationalt1 .l-paginationalt1-x-count {
        display: none; }
      .l-paginationalt1 .l-paginationalt1-x-pages > *:not(.l-paginationalt1-x-count) {
        display: inline-block; } }

.l-resultsheader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  margin-top: 16px; }
  .l-resultsheader .l-resultsheader-x-controls {
    align-items: center;
    display: flex; }
    .l-resultsheader .l-resultsheader-x-controls > *:not(:last-child) {
      margin-right: 16px; }
  @media (min-width: 495px) {
    .l-resultsheader .l-resultsheader-x-controls > *:not(:last-child) {
      margin-right: 24px; } }
  @media (min-width: 768px) {
    .l-resultsheader {
      margin-bottom: 12px;
      margin-top: 20px; } }

.l-roles .roles-x-content {
  padding: 8px; }

@media (min-width: 1024px) {
  .l-roles .roles-x-content {
    padding: 0 8px 8px; } }

.l-search {
  margin-bottom: 16px;
  padding-bottom: 16px;
  padding-top: 16px; }
  .l-search > *:not(:last-child) {
    margin-bottom: 16px; }
  .l-search .l-search-x-filters > * {
    width: 100%; }
  .l-search .l-search-x-filters > *:not(:last-child) {
    margin-bottom: 8px; }
  @media (min-width: 768px) {
    .l-search {
      padding-bottom: 24px;
      padding-top: 24px; }
      .l-search .l-search-x-filters {
        display: flex; }
        .l-search .l-search-x-filters > * {
          box-sizing: border-box;
          padding: 0 8px;
          width: 40%; }
          .l-search .l-search-x-filters > *:not(:last-child) {
            margin-bottom: 0; }
          .l-search .l-search-x-filters > *:first-of-type {
            padding-left: 0;
            width: 60%; }
          .l-search .l-search-x-filters > *:last-of-type {
            padding-right: 0; } }
  @media (min-width: 1024px) {
    .l-search {
      padding-bottom: 40px;
      padding-top: 40px; } }

.l-siteerror {
  padding-top: 48px; }
  .l-siteerror .l-siteerror-x-snippets {
    padding-top: 32px;
    padding-bottom: 32px; }
    .l-siteerror .l-siteerror-x-snippets > *:not(:last-child) {
      margin-bottom: 32px; }
  .l-siteerror .l-siteerror-x-text {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 48px; }
    .l-siteerror .l-siteerror-x-text > *:not(:last-child) {
      margin: 0 auto 32px auto; }
  .l-siteerror .l-siteerror-x-snippets,
  .l-siteerror .l-siteerror-x-text {
    max-width: 260px; }
  @media (min-width: 768px) {
    .l-siteerror {
      padding-top: 48px; }
      .l-siteerror .l-siteerror-x-snippets {
        display: flex; }
        .l-siteerror .l-siteerror-x-snippets > * {
          box-sizing: border-box;
          width: 50%; }
          .l-siteerror .l-siteerror-x-snippets > *:not(:last-child) {
            margin-bottom: 0; }
          .l-siteerror .l-siteerror-x-snippets > *:nth-child(odd) {
            padding-right: 32px; }
          .l-siteerror .l-siteerror-x-snippets > *:nth-child(even) {
            padding-left: 32px; }
      .l-siteerror .l-siteerror-x-snippets,
      .l-siteerror .l-siteerror-x-text {
        margin-left: auto;
        margin-right: auto;
        max-width: 560px; } }

.l-switchselect .l-switchselect-x-mid,
.l-switchselect .l-switchselect-x-tablet {
  display: none; }

@media (min-width: 495px) {
  .l-switchselect .l-switchselect-x-mobile {
    display: none; }
  .l-switchselect .l-switchselect-x-mid {
    display: block; } }

@media (min-width: 768px) {
  .l-switchselect .l-switchselect-x-mid {
    display: none; }
  .l-switchselect .l-switchselect-x-tablet {
    display: block; } }

.l-useraction {
  flex-direction: column;
  display: flex;
  width: 100%; }
  .l-useraction .l-useraction-x-box {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 40px 24px; }
  @media (min-width: 1024px) {
    .l-useraction {
      flex-direction: row; }
      .l-useraction .l-useraction-x-box {
        height: 100vh;
        padding: 0 0 0 calc(50vw - 500px);
        width: 50%; }
        .l-useraction .l-useraction-x-box:not(:first-of-type) {
          padding: 0 calc(50vw - 500px) 0 0; } }

.l-menudropdown {
  position: relative; }
  .l-menudropdown .l-menudropdown-x-menu {
    opacity: 0;
    padding-top: 4px;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: calc(100%);
    transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 3; }
  .l-menudropdown.l-menudropdown-is-active .l-menudropdown-x-menu {
    opacity: 1;
    pointer-events: auto; }

.l-menudropdown-left .l-menudropdown-x-menu {
  left: 0;
  right: auto; }

.l-menudropdown-big {
  width: 100%; }
  .l-menudropdown-big .l-menudropdown-x-menu {
    width: 100%; }

.l-menumobile {
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: translateY(100%);
  transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1), transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 100%;
  z-index: 4; }
  .l-menumobile .l-menumobile-x-close {
    margin-top: 24px; }
  .l-menumobile .l-menumobile-x-secondary {
    align-items: flex-start;
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px; }
    .l-menumobile .l-menumobile-x-secondary > * {
      flex: 0 0 auto; }
  .l-menumobile.l-menumobile-is-open {
    opacity: 1;
    pointer-events: auto;
    transform: none; }
  @media (min-width: 768px) {
    .l-menumobile .l-menumobile-x-close {
      margin-top: 0;
      position: absolute;
      right: 16px;
      top: 16px; } }

.l-menudesktop {
  border-bottom: 1px solid #493e37;
  display: flex;
  flex-direction: column;
  height: 80px;
  position: relative; }
  .l-menudesktop .l-menudesktop-x-primary,
  .l-menudesktop .l-menudesktop-x-secondary {
    display: flex;
    justify-content: flex-end; }
  .l-menudesktop .l-menudesktop-x-primarynav,
  .l-menudesktop .l-menudesktop-x-profile,
  .l-menudesktop .l-menudesktop-x-secondary {
    display: none; }
  .l-menudesktop .l-menudesktop-x-logo {
    left: 0;
    position: absolute;
    top: 0; }
  .l-menudesktop .l-menudesktop-x-primary {
    align-items: center;
    flex: 1 1 100%;
    order: 2; }
  .l-menudesktop .l-menudesktop-x-secondary {
    display: none;
    flex: 0 0 auto;
    justify-content: flex-end;
    order: 1;
    padding-bottom: 8px;
    padding-top: 8px; }
  @media (min-width: 1024px) {
    .l-menudesktop {
      height: 112px; }
      .l-menudesktop .l-menudesktop-x-primarynav,
      .l-menudesktop .l-menudesktop-x-profile {
        display: block; }
      .l-menudesktop .l-menudesktop-x-primary {
        align-items: flex-end; }
      .l-menudesktop .l-menudesktop-x-profile {
        margin-bottom: 24px;
        margin-left: 16px; }
      .l-menudesktop .l-menudesktop-x-secondary {
        display: flex; }
      .l-menudesktop .l-menudesktop-x-trigger {
        display: none; } }
  @media (min-width: 1032px) {
    .l-menudesktop .l-menudesktop-x-logo {
      left: calc(50vw - 500px); } }

.l-menudesktop-noborder {
  border-bottom: none; }

.l-menudesktopitems {
  display: flex; }

.l-message {
  max-width: 288px; }
  @media (min-width: 768px) {
    .l-message {
      max-width: none; } }

.l-textmessage {
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%; }
  .l-textmessage > *:not(:last-child) {
    margin: 0 auto 32px auto; }
  .l-textmessage .l-textmessage-x-bordered > * {
    border-color: #fff; }

.l-toast {
  max-width: 288px;
  opacity: 0;
  position: fixed;
  right: 16px;
  top: 96px;
  transform: translateX(100%);
  transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1), transform 600ms cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 3;
  width: 100%; }
  .l-toast.l-toast-is-open {
    opacity: 1;
    transform: none; }
  @media (min-width: 768px) {
    .l-toast {
      max-width: 505px; } }
  @media (min-width: 1024px) {
    .l-toast {
      top: 128px; } }

.l-form {
  margin-bottom: 16px;
  margin-top: 16px;
  max-width: 560px; }
  @media (min-width: 768px) {
    .l-form {
      margin-bottom: 64px;
      margin-top: 32px; } }
  @media (min-width: 1024px) {
    .l-form {
      max-width: none; } }

.l-jumpsection {
  margin-bottom: 12px;
  margin-top: 12px; }
  @media (min-width: 784px) {
    .l-jumpsection {
      display: none; } }

.l-statusfilters {
  margin-bottom: 16px; }
  @media (min-width: 768px) {
    .l-statusfilters {
      margin-bottom: 32px; } }

.l-table {
  margin-bottom: 16px; }
  @media (min-width: 768px) {
    .l-table {
      margin-bottom: 32px; } }
  @media (min-width: 1024px) {
    .l-table {
      margin-bottom: 96px; } }

.l-tabledetail {
  margin-bottom: 24px;
  margin-top: 24px; }

.l-textruled {
  margin-bottom: 8px;
  margin-top: 16px; }
  @media (min-width: 768px) {
    .l-textruled {
      margin-bottom: 16px;
      margin-top: 12px; } }

.accordionitem {
  border-bottom: 1px solid #f1f2e7; }
  .accordionitem .accordionitem-x-header {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding-bottom: 8px; }
    .accordionitem .accordionitem-x-header button {
      box-shadow: 0 1px 0 0 #2578a2;
      color: #2578a2;
      font-weight: bold;
      outline-offset: 4px;
      text-decoration: none;
      transition: box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1);
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      margin-top: 4px; }
      .accordionitem .accordionitem-x-header button:focus, .accordionitem .accordionitem-x-header button:hover {
        box-shadow: 0 2px 0 0 #2578a2; }
  .accordionitem .accordionitem-x-content {
    opacity: 0;
    pointer-events: none;
    position: absolute; }
  .accordionitem.accordionitem-is-active .accordionitem-x-content {
    opacity: 1;
    pointer-events: auto;
    position: relative;
    transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  @media (min-width: 768px) {
    .accordionitem .accordionitem-x-header {
      align-items: center;
      display: flex;
      justify-content: space-between; } }

.appoverview .appoverview-x-actions {
  margin-bottom: 16px;
  min-height: 120px;
  padding-left: 136px; }
  .appoverview .appoverview-x-actions li:not(:last-of-type) {
    margin-bottom: 8px; }
  .appoverview .appoverview-x-actions ul {
    margin-bottom: 16px; }

.appoverview .appoverview-x-details a {
  box-shadow: 0 1px 0 0 #2578a2;
  color: #2578a2;
  font-weight: bold;
  outline-offset: 4px;
  text-decoration: none;
  transition: box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1);
  font-size: 12px;
  font-weight: 700;
  line-height: 16px; }
  .appoverview .appoverview-x-details a:focus, .appoverview .appoverview-x-details a:hover {
    box-shadow: 0 2px 0 0 #2578a2; }

.appoverview .appoverview-x-details h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; }
  .appoverview .appoverview-x-details h4:not(:first-child) {
    margin-top: 16px; }

.appoverview .appoverview-x-heading {
  margin-bottom: 16px;
  position: relative; }
  .appoverview .appoverview-x-heading h1 {
    font-family: "museo-slab", sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 48px; }

.appoverview .appoverview-x-image {
  height: 120px;
  position: absolute;
  top: calc(100% + 16px);
  width: 120px; }

@media (min-width: 768px) {
  .appoverview .appoverview-x-actions {
    border-bottom: 1px solid #f7f7f7;
    padding-left: 0; }
  .appoverview .appoverview-x-container {
    padding-left: 152px;
    position: relative; }
  .appoverview .appoverview-x-heading {
    position: initial; }
    .appoverview .appoverview-x-heading h1 {
      font-family: "museo-slab", sans-serif;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      margin-bottom: 8px; }
  .appoverview .appoverview-x-image {
    left: 0;
    top: 0; } }

.appoverview-sm .appoverview-x-actions {
  min-height: 0; }

.appoverview-sm .appoverview-x-body {
  min-height: 120px; }

.appoverview-sm .appoverview-x-details {
  padding-left: 136px; }

@media (min-width: 768px) {
  .appoverview-sm .appoverview-x-details {
    padding-left: 0; } }

.attachment {
  border: 1px solid #d9dacd;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 16px;
  position: relative; }
  .attachment:after {
    background: #f7f7f7;
    bottom: 100%;
    border: solid #d9dacd;
    border-width: 0 1px 1px 0;
    content: '';
    display: inline-block;
    left: 20px;
    padding: 5px;
    position: absolute;
    transform: rotate(-135deg) translate3d(0, -50%, 0); }

.attachment-big {
  border-radius: 4px;
  margin-top: 24px; }
  .attachment-big:after {
    display: none; }

.attachment-noradius {
  border-radius: 0; }

.attachment-borderorange {
  border-color: #a76825; }
  .attachment-borderorange:after {
    background: #fff;
    border-color: #a76825; }

.attachment-borderblue {
  border-color: #2578a2; }
  .attachment-borderblue:after {
    background: #fff;
    border-color: #2578a2; }

.attachment-blue:after {
  background: #f7fbfd; }

.attachment-inner {
  border-radius: 4px;
  margin-top: 8px; }
  .attachment-inner:not(:last-of-type) {
    margin-bottom: 24px; }
  .attachment-inner:after {
    display: block; }

.attachment-noarrow:after {
  display: none; }

.button {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background-color: #2578a2;
  border: 1px solid #2578a2;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  min-width: 76px;
  outline: none;
  padding: 8px 16px;
  text-align: center;
  transition: background-color 600ms cubic-bezier(0.19, 1, 0.22, 1), border-color 600ms cubic-bezier(0.19, 1, 0.22, 1), box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1), color 600ms cubic-bezier(0.19, 1, 0.22, 1);
  user-select: none; }
  .button:active:focus {
    background-color: #004861;
    box-shadow: inset 0 1px 4px 0 #493e37;
    color: #fff; }
  .button:focus {
    border-color: #2578a2;
    box-shadow: 0 0 6px 0 #2578a2; }
  .button:hover {
    background-color: #004861;
    border-color: #004861;
    color: #fff; }
  .button[disabled] {
    background-color: #f7f7f7;
    border-color: #f7f7f7;
    color: #9a9a9a;
    cursor: not-allowed; }
    .button[disabled]:hover {
      color: #9a9a9a; }
  .button .button-x-longtext {
    display: none; }
  @media (min-width: 768px) {
    .button .button-x-longtext {
      display: initial; } }

@media (min-width: 768px) {
  .button-large {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px; } }

.button-more {
  box-sizing: content-box;
  height: 16px;
  min-width: auto;
  padding: 12px;
  position: relative;
  width: 16px; }
  .button-more .button-x-text {
    background: url("images/button-more.svg") center center no-repeat;
    color: transparent;
    display: block;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    user-select: none;
    width: 16px; }
  .button-more[disabled] .button-x-text {
    background-image: url("images/button-more-disabled.svg"); }

.button-more.button-secondary .button-x-text {
  background-image: url("images/button-more-secondary.svg"); }

.button-more.button-secondary:hover .button-x-text {
  background-image: url("images/button-more.svg"); }

.button-more.button-secondary[disabled] .button-x-text {
  background-image: url("images/button-more-disabled.svg"); }

.button-more.button-small {
  padding: 8px; }

.button-secondary {
  background-color: #fff;
  color: #2578a2; }
  .button-secondary[disabled] {
    background-color: #fff;
    border-color: #9a9a9a; }

.button-tertiary {
  background-color: transparent;
  border: none;
  color: #2578a2; }
  .button-tertiary:active:focus {
    background-color: transparent;
    box-shadow: none;
    outline: 1px solid #2578a2;
    color: #004861; }
  .button-tertiary:focus {
    box-shadow: none;
    outline: 1px solid #2578a2;
    color: #004861; }
  .button-tertiary:active, .button-tertiary:hover {
    outline: 1px solid #2578a2;
    background-color: transparent;
    color: #004861; }
  .button-tertiary[disabled] {
    background-color: transparent;
    border: none; }

.button-text {
  background-color: transparent;
  border: none;
  color: #2578a2;
  min-width: auto;
  padding: 0; }
  .button-text:active:focus {
    background-color: transparent;
    box-shadow: none;
    color: #004861; }
  .button-text:active, .button-text:focus, .button-text:hover {
    background-color: transparent;
    box-shadow: none;
    color: #004861; }
  .button-text[disabled] {
    background-color: transparent;
    border: none; }

.button-small {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  padding-left: 12px;
  padding-right: 12px; }

.searchempty {
  padding: 12px 0 32px 0; }
  .searchempty .searchempty-x-content {
    align-items: flex-start;
    display: flex; }
  .searchempty .searchempty-x-description {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    list-style-type: disc;
    padding-left: 16px; }
    .searchempty .searchempty-x-description > * {
      padding: 0 0 4px 16px; }
  .searchempty .searchempty-x-heading {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 8px; }
  .searchempty .searchempty-x-image {
    border: 1px dashed #493e37;
    border-radius: 2px;
    flex: 0 0 80px;
    height: 80px;
    left: auto;
    margin-right: 16px;
    position: relative;
    top: auto;
    width: auto; }
  @media (min-width: 768px) {
    .searchempty .searchempty-x-content {
      align-items: center; }
    .searchempty .searchempty-x-heading {
      font-size: 24px;
      font-weight: 300;
      line-height: 32px;
      margin-bottom: 16px; }
    .searchempty .searchempty-x-image {
      flex: 0 0 212px;
      height: 212px;
      margin-right: 24px; }
    .searchempty .searchempty-x-text {
      width: auto; } }

.calltoaction {
  background-color: #f1f2e7;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 24px; }
  .calltoaction .calltoaction-x-button {
    width: 100%; }
  .calltoaction .calltoaction-x-buttonsm {
    min-width: auto;
    padding: 0; }
  .calltoaction .calltoaction-x-button,
  .calltoaction .calltoaction-x-buttonsm {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px; }
  .calltoaction .calltoaction-x-text {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px; }
  @media (min-width: 768px) {
    .calltoaction {
      align-items: center;
      flex-direction: row;
      padding: 16px 16px 16px 24px; }
      .calltoaction .calltoaction-x-text {
        margin-bottom: 0;
        margin-right: 32px; }
      .calltoaction .calltoaction-x-buttonsm {
        min-width: 76px; } }

.calltoaction-edit {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  .calltoaction-edit .calltoaction-x-button {
    order: 2; }
  .calltoaction-edit .calltoaction-x-buttonsm,
  .calltoaction-edit .calltoaction-x-text {
    margin-bottom: 16px; }
  @media (min-width: 768px) {
    .calltoaction-edit {
      flex-wrap: initial;
      justify-content: initial; }
      .calltoaction-edit .calltoaction-x-button {
        order: initial; }
      .calltoaction-edit .calltoaction-x-buttonsm,
      .calltoaction-edit .calltoaction-x-text {
        margin-bottom: 0; } }

.calltoaction-pay {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between; }
  .calltoaction-pay .calltoaction-x-buttonbox {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    .calltoaction-pay .calltoaction-x-buttonbox > * {
      width: 100%; }
    .calltoaction-pay .calltoaction-x-buttonbox p {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px;
      margin-bottom: 8px;
      text-transform: uppercase; }
    .calltoaction-pay .calltoaction-x-buttonbox .calltoaction-x-twobuttons {
      display: flex;
      justify-content: space-between; }
      .calltoaction-pay .calltoaction-x-buttonbox .calltoaction-x-twobuttons > * {
        margin-right: 8px;
        width: 100%; }
        .calltoaction-pay .calltoaction-x-buttonbox .calltoaction-x-twobuttons > *:last-of-type {
          margin-right: 0; }
  .calltoaction-pay .calltoaction-x-buttonsm,
  .calltoaction-pay .calltoaction-x-text {
    margin-bottom: 16px; }

.calltoaction-white {
  background: #fff;
  padding: 0; }

.card {
  background-color: #fff;
  border: 1px solid #f1f2e7;
  border-radius: 4px;
  box-shadow: 0 2px 0 0 #f1f2e7, 0 0 4px 0 #f1f2e7;
  display: block;
  overflow: hidden;
  transition: border-color 600ms cubic-bezier(0.19, 1, 0.22, 1), box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .card[href]:hover {
    border-color: #d9dacd;
    box-shadow: 0 4px 0 0 #d9dacd, 0 0 8px 0 #d9dacd; }
  .card[href]:focus {
    border-color: #2578a2;
    box-shadow: 0 2px 0 0 #2578a2, 0 0 4px 0 #2578a2;
    outline: none; }

.cardcredential {
  align-items: flex-start;
  display: flex;
  padding: 8px 16px 16px 8px;
  position: relative; }
  .cardcredential .cardcredential-x-image {
    border-radius: 4px;
    flex: 0 0 auto;
    overflow: hidden;
    width: 80px; }
    .cardcredential .cardcredential-x-image img {
      display: block;
      height: auto;
      width: 100%; }
  .cardcredential .cardcredential-x-status {
    align-items: stretch;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    display: flex;
    flex-direction: column;
    left: 8px;
    overflow: hidden;
    position: absolute;
    top: 84px;
    width: 80px; }
  .cardcredential .cardcredential-x-text {
    margin-left: 16px;
    margin-top: 8px; }
    .cardcredential .cardcredential-x-text > p {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px;
      border-bottom: 1px solid #f1f2e7;
      color: #2578a2;
      margin-bottom: 16px;
      padding-bottom: 16px;
      transition: color; }
    .cardcredential .cardcredential-x-text li {
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
      color: #493e37; }
      .cardcredential .cardcredential-x-text li:not(:last-child) {
        margin-bottom: 8px; }
  .cardcredential:hover .cardcredential-x-text > p {
    color: #004861; }
  @media (min-width: 496px) {
    .cardcredential {
      display: block;
      padding: 0;
      width: 100%; }
      .cardcredential .cardcredential-x-image {
        border-radius: 0;
        width: auto; }
      .cardcredential .cardcredential-x-status {
        border-radius: 0;
        left: auto;
        right: 0;
        top: 0;
        width: auto; }
      .cardcredential .cardcredential-x-text {
        margin: 0;
        padding: 16px; }
        .cardcredential .cardcredential-x-text > p {
          min-height: 48px; } }

.carddownload {
  border: none;
  box-shadow: none;
  margin-bottom: 8px;
  padding: 16px; }
  .carddownload .carddownload-x-actions {
    box-sizing: border-box; }
  .carddownload .carddownload-x-content {
    width: 100%; }
  .carddownload .carddownload-x-header {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    border-bottom: 1px solid #f1f2e7;
    padding-bottom: 8px;
    margin-bottom: 8px; }
  .carddownload .carddownload-x-info {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    margin-bottom: 16px; }
    .carddownload .carddownload-x-info h5 {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px; }
  @media (min-width: 768px) {
    .carddownload {
      display: flex; }
      .carddownload .carddownload-x-actions {
        width: auto; }
      .carddownload .carddownload-x-content {
        align-items: flex-start;
        display: flex;
        justify-content: space-between; }
      .carddownload .carddownload-x-info {
        margin-bottom: 0; }
      .carddownload .carddownload-x-text {
        flex: 0 0 560px; } }

.cardreview {
  padding: 16px 16px 74px;
  position: relative; }
  .cardreview table {
    display: none;
    margin-bottom: 16px;
    margin-top: 16px;
    table-layout: fixed;
    width: 100%; }
    .cardreview table td,
    .cardreview table th {
      text-align: left; }
    .cardreview table th {
      font-size: 12px;
      font-weight: 700;
      line-height: 16px; }
  .cardreview .cardreview-x-actions {
    border-top: 1px solid #f1f2e7;
    bottom: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    left: 16px;
    padding-top: 16px;
    position: absolute;
    width: calc(100% - 32px); }
  .cardreview .cardreview-x-heading {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; }
  .cardreview .cardreview-x-icon {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px; }
  .cardreview .cardreview-x-image {
    border-radius: 4px;
    height: 80px;
    left: 8px;
    overflow: hidden;
    position: absolute;
    top: 8px;
    width: 80px; }
    .cardreview .cardreview-x-image img {
      display: block;
      height: auto;
      width: 100%; }
  .cardreview .cardreview-x-text {
    margin-bottom: 16px;
    min-height: 80px;
    padding-left: 96px; }
  @media (min-width: 496px) {
    .cardreview table {
      display: table; } }
  @media (min-width: 768px) {
    .cardreview {
      align-items: flex-start;
      display: flex;
      padding: 16px; }
      .cardreview header {
        align-items: flex-start;
        display: flex;
        justify-content: space-between; }
      .cardreview table {
        border-top: 1px solid #f1f2e7;
        margin-bottom: 0; }
        .cardreview table th {
          padding-top: 16px; }
      .cardreview .cardreview-x-actions {
        border-top: 0;
        bottom: auto;
        left: auto;
        padding-top: 0;
        position: relative;
        width: auto; }
      .cardreview .cardreview-x-content {
        flex: 1 1 100%; }
      .cardreview .cardreview-x-heading {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px; }
      .cardreview .cardreview-x-image {
        flex: 0 0 120px;
        height: 120px;
        left: auto;
        margin-right: 24px;
        position: relative;
        top: auto;
        width: auto; }
      .cardreview .cardreview-x-text {
        margin-bottom: 0;
        min-height: auto;
        padding-left: 0; } }
  @media (min-width: 1024px) {
    .cardreview table,
    .cardreview .cardreview-x-text {
      width: 400px; } }

.cardreview-empty {
  padding-bottom: 16px; }
  .cardreview-empty .cardreview-x-image {
    border: 1px dashed #493e37;
    border-radius: 2px; }
  .cardreview-empty .cardreview-x-heading {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px; }
  .cardreview-empty .cardreview-x-description {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px; }
  .cardreview-empty .cardreview-x-actions {
    border: none;
    display: block;
    position: initial; }
    .cardreview-empty .cardreview-x-actions > * {
      width: 100%; }
  @media (min-width: 768px) {
    .cardreview-empty .cardreview-x-content {
      align-items: center;
      display: flex; }
    .cardreview-empty .cardreview-x-actions > * {
      margin-top: 16px;
      width: initial; } }
  @media (min-width: 1024px) {
    .cardreview-empty .cardreview-x-heading {
      font-size: 24px;
      font-weight: 300;
      line-height: 32px; }
    .cardreview-empty .cardreview-x-text {
      width: auto; } }

.dialog {
  background-color: rgba(73, 62, 55, 0.6);
  opacity: 0;
  overflow-y: auto;
  pointer-events: none; }
  .dialog .dialog-x-content {
    border-radius: 2px;
    margin: 25vh auto;
    width: 288px; }
  .dialog .dialog-x-close {
    position: relative; }
    .dialog .dialog-x-close > button {
      color: transparent;
      height: 16px;
      overflow: hidden;
      position: absolute;
      right: 16px;
      top: 16px;
      user-select: none;
      width: 16px; }
      .dialog .dialog-x-close > button::before, .dialog .dialog-x-close > button::after {
        background: center center no-repeat;
        content: '';
        cursor: pointer;
        display: block;
        height: 16px;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
        width: 16px; }
      .dialog .dialog-x-close > button::before {
        background-image: url("images/toast-button.svg"); }
      .dialog .dialog-x-close > button::after {
        background-image: url("images/toast-button-hover.svg");
        opacity: 0; }
      .dialog .dialog-x-close > button:hover::before {
        opacity: 0; }
      .dialog .dialog-x-close > button:hover::after {
        opacity: 1; }
      .dialog .dialog-x-close > button:focus {
        outline: none; }
  .dialog.dialog-is-open {
    opacity: 1;
    pointer-events: auto; }
  @media (min-width: 768px) {
    .dialog .dialog-x-content {
      width: 520px; } }

.dialogheader {
  padding-bottom: 16px;
  border-bottom: 1px solid #f1f2e7; }
  .dialogheader .dialogheader-x-heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px; }

.document {
  background-color: rgba(73, 62, 55, 0.98);
  padding: 16px 16px 0;
  position: relative; }
  .document .document-x-content {
    height: 100vh;
    position: relative; }
  .document .document-x-name {
    background: #fff;
    margin-bottom: 24px;
    position: relative; }
    .document .document-x-name p {
      padding: 8px 36px; }
  .document .document-x-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .document .document-x-icon > * {
      padding: 0; }
      .document .document-x-icon > *:before {
        position: relative; }
    .document .document-x-icon p {
      padding: 0; }
    .document .document-x-icon:first-of-type {
      left: 12px; }
    .document .document-x-icon:last-of-type {
      right: 12px; }
  .document .document-x-button {
    background: #fff;
    border-radius: 2px;
    bottom: -4px;
    height: 40px;
    position: fixed;
    top: initial;
    transform: translateY(-50%);
    width: 40px; }
    .document .document-x-button > * {
      height: 100%;
      width: 100%; }
  .document .documentbutton-x-left {
    left: 8px; }
  .document .documentbutton-x-right {
    right: 8px; }
  .document .documenticon-x-left {
    left: 12px; }
  .document .documenticon-x-right {
    right: 12px; }
  @media (min-width: 768px) {
    .document .document-x-button {
      bottom: initial;
      top: calc(50% + 64px); } }

.dropzone {
  background: rgba(37, 120, 162, 0.1);
  border: 1px dashed #2578a2;
  margin-bottom: 8px;
  padding: 16px;
  position: relative;
  transition: background 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .dropzone:hover {
    background: rgba(37, 120, 162, 0.3); }
    .dropzone:hover .dropzone-x-label {
      color: #004861; }
    .dropzone:hover .dropzone-disabled.dropzone-x-label {
      color: #9a9a9a; }
  .dropzone input {
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    outline: none;
    position: absolute;
    top: 0;
    width: 100%; }
  .dropzone .dropzone-x-label {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #2578a2; }
  .dropzone .dropzone-disabled {
    background-color: #f7f7f7;
    border-color: #f7f7f7;
    color: #9a9a9a;
    cursor: not-allowed; }
    .dropzone .dropzone-disabled:hover {
      background-color: #f7f7f7 !important;
      border-color: #f7f7f7 !important;
      color: #9a9a9a !important; }

.dropzone.dropzone-disabled {
  background-color: #f7f7f7;
  border-color: #f7f7f7;
  color: #9a9a9a;
  cursor: not-allowed; }
  .dropzone.dropzone-disabled:hover {
    background-color: #f7f7f7;
    border-color: #f7f7f7;
    color: #9a9a9a; }

.footer {
  background-color: #695c54;
  padding: 56px 16px;
  text-align: center; }
  .footer .footer-x-socials {
    margin-bottom: 16px; }
  @media (min-width: 768px) {
    .footer {
      padding: 40px 16px; } }

.footeraward {
  background-color: #004861;
  color: #fff;
  padding: 64px 16px;
  text-align: center; }
  .footeraward h2 {
    font-family: "museo-slab", sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 16px; }
  .footeraward p {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 auto 32px auto;
    max-width: 560px; }
  @media (min-width: 768px) {
    .footeraward {
      padding: 80px 48px; } }

.footerlinklist {
  display: block;
  text-align: center; }
  .footerlinklist p {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 8px;
    text-transform: uppercase; }
    .footerlinklist p a {
      color: #fff;
      display: block; }
  @media (min-width: 768px) {
    .footerlinklist {
      justify-content: center;
      display: flex; }
      .footerlinklist p {
        margin-bottom: 0; }
        .footerlinklist p:not(:last-child) {
          border-right: 1px solid #fff;
          margin-right: 8px;
          padding-right: 8px; } }

.form fieldset {
  margin-bottom: 32px; }
  .form fieldset legend {
    margin-bottom: 8px; }
  .form fieldset > *:not(:last-child):not(legend) {
    margin-bottom: 16px; }

.form .form-x-heading {
  margin-bottom: 16px; }

.form .form-x-inputs {
  margin-bottom: 32px; }
  .form .form-x-inputs > *:not(:last-child):not(fieldset) {
    margin-bottom: 16px; }

.form .form-x-snippets {
  border: 1px solid #f1f2e7;
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  padding-top: 32px; }
  .form .form-x-snippets > *:not(:last-child) {
    margin-bottom: 32px; }

.form .form-x-submit {
  display: flex;
  justify-content: flex-end; }
  .form .form-x-submit > *:not(last-child) {
    margin-left: 8px; }

@media (min-width: 768px) {
  .form .form-x-heading {
    margin-bottom: 24px; }
  .form .form-x-snippets {
    display: flex; }
    .form .form-x-snippets > * {
      box-sizing: border-box;
      width: 50%; }
      .form .form-x-snippets > *:nth-child(odd) {
        padding-right: 32px; }
      .form .form-x-snippets > *:nth-child(even) {
        padding-left: 32px; } }

@media (min-width: 1024px) {
  .form .form-x-heading,
  .form .form-x-inputs {
    width: 560px; }
  .form .form-x-content {
    align-items: stretch;
    display: flex; }
  .form .form-x-inputs {
    flex: 0 0 auto;
    margin-bottom: 0; }
  .form .form-x-snippets {
    border-left-width: 1px;
    border-top-width: 0;
    display: block;
    margin-left: 64px;
    margin-top: 20px;
    padding-left: 64px;
    padding-top: 0; }
    .form .form-x-snippets > * {
      width: auto; }
      .form .form-x-snippets > *:nth-child(odd) {
        padding-right: 0; }
      .form .form-x-snippets > *:nth-child(even) {
        padding-left: 0; } }

.title {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: block;
  margin-bottom: 4px; }

.helper {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px; }

.formcheckbox {
  position: relative; }
  .formcheckbox input {
    display: block;
    height: 16px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 16px; }
    .formcheckbox input + label {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      cursor: pointer;
      display: block;
      padding-left: 24px; }
      .formcheckbox input + label::before, .formcheckbox input + label::after {
        content: '';
        display: block;
        height: 16px;
        left: 0;
        position: absolute;
        top: 2px;
        width: 16px; }
      .formcheckbox input + label::before {
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 0 0 1px #2578a2;
        transition: background-color 600ms cubic-bezier(0.19, 1, 0.22, 1), box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1); }
      .formcheckbox input + label::after {
        background: url("images/formcheckbox.svg") center center no-repeat;
        opacity: 0;
        transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1); }
      .formcheckbox input + label:active::before {
        box-shadow: 0 0 0 2px #004861; }
      .formcheckbox input + label:hover::before {
        box-shadow: 0 0 0 2px #2578a2; }
    .formcheckbox input:focus + label::before {
      box-shadow: 0 0 0 2px #2578a2, 0 0 8px 0 #2578a2; }
    .formcheckbox input[disabled] + label {
      color: #9a9a9a;
      cursor: not-allowed;
      user-select: none; }
      .formcheckbox input[disabled] + label::before {
        background-color: #f7f7f7;
        box-shadow: 0 0 0 1px #d9dacd; }
    .formcheckbox input:checked + label::before {
      background-color: #004861;
      box-shadow: 0 0 0 2px #004861; }
    .formcheckbox input:checked + label::after {
      opacity: 1; }
    .formcheckbox input:checked:focus + label::before {
      box-shadow: 0 0 0 2px #004861, 0 0 8px 0 #2578a2; }
    .formcheckbox input:checked[disabled] + label {
      cursor: not-allowed; }
      .formcheckbox input:checked[disabled] + label::before {
        background-color: #9a9a9a;
        box-shadow: 0 0 0 2px #9a9a9a; }

.formcheckbox-bold input + label {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; }

.forminput > label,
.forminput .forminput-x-label {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: block;
  margin-bottom: 4px; }
  .forminput > label span,
  .forminput .forminput-x-label span {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px; }

.forminput .forminput-x-input > *:not(:last-of-type),
.forminput .forminput-x-inlineinput > *:not(:last-of-type) {
  margin-bottom: 4px; }

.forminput .forminput-x-input > input,
.forminput .forminput-x-input > select,
.forminput .forminput-x-input > textarea,
.forminput .forminput-x-inlineinput > input,
.forminput .forminput-x-inlineinput > select,
.forminput .forminput-x-inlineinput > textarea {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #2578a2;
  box-sizing: border-box;
  display: block;
  outline: none;
  padding: 8px;
  position: relative;
  transition: box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 100%; }
  .forminput .forminput-x-input > input::-webkit-input-placeholder,
  .forminput .forminput-x-input > select::-webkit-input-placeholder,
  .forminput .forminput-x-input > textarea::-webkit-input-placeholder,
  .forminput .forminput-x-inlineinput > input::-webkit-input-placeholder,
  .forminput .forminput-x-inlineinput > select::-webkit-input-placeholder,
  .forminput .forminput-x-inlineinput > textarea::-webkit-input-placeholder {
    color: #493e37;
    font-weight: normal;
    opacity: 0.6; }
  .forminput .forminput-x-input > input::-moz-placeholder,
  .forminput .forminput-x-input > select::-moz-placeholder,
  .forminput .forminput-x-input > textarea::-moz-placeholder,
  .forminput .forminput-x-inlineinput > input::-moz-placeholder,
  .forminput .forminput-x-inlineinput > select::-moz-placeholder,
  .forminput .forminput-x-inlineinput > textarea::-moz-placeholder {
    color: #493e37;
    font-weight: normal;
    opacity: 0.6; }
  .forminput .forminput-x-input > input:-ms-input-placeholder,
  .forminput .forminput-x-input > select:-ms-input-placeholder,
  .forminput .forminput-x-input > textarea:-ms-input-placeholder,
  .forminput .forminput-x-inlineinput > input:-ms-input-placeholder,
  .forminput .forminput-x-inlineinput > select:-ms-input-placeholder,
  .forminput .forminput-x-inlineinput > textarea:-ms-input-placeholder {
    color: #493e37;
    font-weight: normal;
    opacity: 0.6; }
  .forminput .forminput-x-input > input:-moz-placeholder,
  .forminput .forminput-x-input > select:-moz-placeholder,
  .forminput .forminput-x-input > textarea:-moz-placeholder,
  .forminput .forminput-x-inlineinput > input:-moz-placeholder,
  .forminput .forminput-x-inlineinput > select:-moz-placeholder,
  .forminput .forminput-x-inlineinput > textarea:-moz-placeholder {
    color: #493e37;
    font-weight: normal;
    opacity: 0.6; }
  .forminput .forminput-x-input > input:active:focus,
  .forminput .forminput-x-input > select:active:focus,
  .forminput .forminput-x-input > textarea:active:focus,
  .forminput .forminput-x-inlineinput > input:active:focus,
  .forminput .forminput-x-inlineinput > select:active:focus,
  .forminput .forminput-x-inlineinput > textarea:active:focus {
    box-shadow: 0 0 0 2px #004861; }
  .forminput .forminput-x-input > input:hover,
  .forminput .forminput-x-input > select:hover,
  .forminput .forminput-x-input > textarea:hover,
  .forminput .forminput-x-inlineinput > input:hover,
  .forminput .forminput-x-inlineinput > select:hover,
  .forminput .forminput-x-inlineinput > textarea:hover {
    box-shadow: 0 0 0 2px #2578a2; }
  .forminput .forminput-x-input > input:focus,
  .forminput .forminput-x-input > select:focus,
  .forminput .forminput-x-input > textarea:focus,
  .forminput .forminput-x-inlineinput > input:focus,
  .forminput .forminput-x-inlineinput > select:focus,
  .forminput .forminput-x-inlineinput > textarea:focus {
    box-shadow: 0 0 0 2px #2578a2, 0 0 8px 0 #2578a2; }
  .forminput .forminput-x-input > input[disabled],
  .forminput .forminput-x-input > select[disabled],
  .forminput .forminput-x-input > textarea[disabled],
  .forminput .forminput-x-inlineinput > input[disabled],
  .forminput .forminput-x-inlineinput > select[disabled],
  .forminput .forminput-x-inlineinput > textarea[disabled] {
    cursor: not-allowed; }

.forminput .forminput-x-input > input[disabled],
.forminput .forminput-x-inlineinput > input[disabled] {
  background-color: #f7f7f7;
  box-shadow: 0 0 0 1px #d9dacd; }

.forminput .forminput-x-input > input[type="date"],
.forminput .forminput-x-inlineinput > input[type="date"] {
  text-transform: uppercase; }
  .forminput .forminput-x-input > input[type="date"]::-webkit-inner-spin-button, .forminput .forminput-x-input > input[type="date"]::-webkit-outer-spin-button,
  .forminput .forminput-x-inlineinput > input[type="date"]::-webkit-inner-spin-button,
  .forminput .forminput-x-inlineinput > input[type="date"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    opacity: 0; }

.forminput .forminput-x-input > select,
.forminput .forminput-x-inlineinput > select {
  background: url("images/forminput-select.svg") calc(100% - 8px) center no-repeat #fff;
  padding-right: 32px; }
  .forminput .forminput-x-input > select[disabled],
  .forminput .forminput-x-inlineinput > select[disabled] {
    background: url("images/forminput-select-disabled.svg") calc(100% - 8px) center no-repeat #f7f7f7;
    box-shadow: 0 0 0 1px #d9dacd; }

.forminput .forminput-x-input > textarea,
.forminput .forminput-x-inlineinput > textarea {
  min-height: 164px;
  resize: vertical; }
  .forminput .forminput-x-input > textarea[disabled],
  .forminput .forminput-x-inlineinput > textarea[disabled] {
    background-color: #f7f7f7;
    box-shadow: 0 0 0 1px #d9dacd;
    height: 100%;
    resize: none; }

.forminput .forminput-x-errortext {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-top: 4px; }

.forminput .forminput-x-sublabel {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  display: block; }

.forminput .forminput-x-inlineinput {
  display: flex; }
  .forminput .forminput-x-inlineinput > input {
    flex: 1 1 auto;
    margin-right: 8px;
    width: auto; }
  .forminput .forminput-x-inlineinput .forminput-x-inlineerror {
    width: 100%; }

.forminput .forminput-x-inlineinputsm > input {
  margin-right: 8px;
  width: 100%; }

.forminput .forminput-x-inputsearch > input {
  background: url("images/formsearch.svg") calc(100% - 8px) center no-repeat rgba(37, 120, 162, 0.08);
  box-shadow: 0 0 0 2px #2578a2;
  padding: 8px 32px 8px 8px; }
  .forminput .forminput-x-inputsearch > input::-webkit-input-placeholder {
    opacity: 1; }
  .forminput .forminput-x-inputsearch > input::-moz-placeholder {
    opacity: 1; }
  .forminput .forminput-x-inputsearch > input:-ms-input-placeholder {
    opacity: 1; }
  .forminput .forminput-x-inputsearch > input:-moz-placeholder {
    opacity: 1; }
  .forminput .forminput-x-inputsearch > input:active:focus {
    box-shadow: 0 0 0 2px #004861; }
  .forminput .forminput-x-inputsearch > input:hover {
    box-shadow: 0 0 0 2px #2578a2; }
  .forminput .forminput-x-inputsearch > input:focus {
    box-shadow: 0 0 0 2px #2578a2, 0 0 8px 0 #2578a2; }

@media (min-width: 768px) {
  .forminput .forminput-x-inlineinputsm > input {
    width: auto; } }

.forminput-small {
  max-width: 560px; }

.forminput-attachment > .forminput-x-input > textarea,
.forminput-attachment > .forminput-x-inlineinput > textarea {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 2px); }

.forminput-inputsspaced .forminput-x-input:not(:last-of-type) {
  margin-bottom: 16px; }

.forminput-x-confirm .forminput-x-input > input,
.forminput-x-confirm .forminput-x-input > select,
.forminput-x-confirm .forminput-x-input > textarea,
.forminput-x-confirm .forminput-x-inlineinput > input,
.forminput-x-confirm .forminput-x-inlineinput > select,
.forminput-x-confirm .forminput-x-inlineinput > textarea {
  box-shadow: 0 0 0 1px #368170; }

.forminput-x-confirm .forminput-x-input .forminput-x-errortext,
.forminput-x-confirm .forminput-x-inlineinput .forminput-x-errortext {
  color: #368170; }

.forminput-x-confirm .forminput-x-errortext {
  color: #368170; }

.forminput-x-leveled .forminput-x-input > input,
.forminput-x-leveled .forminput-x-input > select,
.forminput-x-leveled .forminput-x-input > textarea,
.forminput-x-leveled .forminput-x-inlineinput > input,
.forminput-x-leveled .forminput-x-inlineinput > select,
.forminput-x-leveled .forminput-x-inlineinput > textarea {
  box-shadow: 0 0 0 1px #894198; }

.forminput-x-leveled .forminput-x-input .forminput-x-errortext,
.forminput-x-leveled .forminput-x-inlineinput .forminput-x-errortext {
  color: #894198; }

.forminput-x-leveled .forminput-x-errortext {
  color: #894198; }

.forminput-x-progress .forminput-x-input > input,
.forminput-x-progress .forminput-x-input > select,
.forminput-x-progress .forminput-x-input > textarea,
.forminput-x-progress .forminput-x-inlineinput > input,
.forminput-x-progress .forminput-x-inlineinput > select,
.forminput-x-progress .forminput-x-inlineinput > textarea {
  box-shadow: 0 0 0 1px #a76825; }

.forminput-x-progress .forminput-x-input .forminput-x-errortext,
.forminput-x-progress .forminput-x-inlineinput .forminput-x-errortext {
  color: #a76825; }

.forminput-x-progress .forminput-x-errortext {
  color: #a76825; }

.forminput-x-error .forminput-x-input > input,
.forminput-x-error .forminput-x-input > select,
.forminput-x-error .forminput-x-input > textarea,
.forminput-x-error .forminput-x-inlineinput > input,
.forminput-x-error .forminput-x-inlineinput > select,
.forminput-x-error .forminput-x-inlineinput > textarea {
  box-shadow: 0 0 0 1px #3C7AA2; }

.forminput-x-error .forminput-x-input .forminput-x-errortext,
.forminput-x-error .forminput-x-inlineinput .forminput-x-errortext {
  color: #3C7AA2; }

.forminput-x-error .forminput-x-errortext {
  color: #3C7AA2; }

.forminput-x-all .forminput-x-input > input,
.forminput-x-all .forminput-x-input > select,
.forminput-x-all .forminput-x-input > textarea,
.forminput-x-all .forminput-x-inlineinput > input,
.forminput-x-all .forminput-x-inlineinput > select,
.forminput-x-all .forminput-x-inlineinput > textarea {
  box-shadow: 0 0 0 1px #4a61ac; }

.forminput-x-all .forminput-x-input .forminput-x-errortext,
.forminput-x-all .forminput-x-inlineinput .forminput-x-errortext {
  color: #4a61ac; }

.forminput-x-all .forminput-x-errortext {
  color: #4a61ac; }

.forminput-arrow .forminput-x-input > input {
  background: url("images/forminput-select.svg") calc(100% - 8px) center no-repeat #fff;
  padding-right: 32px; }
  .forminput-arrow .forminput-x-input > input[disabled] {
    background: url("images/forminput-select-disabled.svg") calc(100% - 8px) center no-repeat #f7f7f7; }

.formlegend {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; }

.formradio {
  position: relative; }
  .formradio input {
    display: block;
    height: 16px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 16px; }
    .formradio input + label {
      cursor: pointer;
      display: block;
      padding-left: 24px; }
      .formradio input + label::before, .formradio input + label::after {
        content: '';
        display: block;
        position: absolute; }
      .formradio input + label::before {
        height: 16px;
        left: 0;
        top: 2px;
        width: 16px;
        border: 1px solid #004861;
        border-radius: 100%;
        background-color: #fff; }
      .formradio input + label::after {
        width: 12px;
        height: 12px;
        background: #004861;
        border-radius: 100%;
        top: 5px;
        left: 3px;
        opacity: 0;
        transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1); }
      .formradio input + label:active::before {
        box-shadow: 0 0 0 1px #004861; }
      .formradio input + label:hover::before {
        box-shadow: 0 0 0 1px #2578a2; }
    .formradio input:focus + label::before {
      box-shadow: 0 0 0 1px #2578a2, 0 0 8px 0 #2578a2; }
    .formradio input[disabled] + label {
      cursor: not-allowed;
      user-select: none; }
      .formradio input[disabled] + label::before {
        background-color: #f7f7f7;
        border: 1px solid #d9dacd; }
      .formradio input[disabled] + label:hover::before {
        box-shadow: none; }
    .formradio input:checked + label::before {
      box-shadow: 0 0 0 1px #004861; }
    .formradio input:checked + label::after {
      opacity: 1; }
    .formradio input:checked:focus + label::before {
      box-shadow: 0 0 0 1px #004861, 0 0 8px 0 #2578a2; }
    .formradio input:checked[disabled] + label {
      cursor: not-allowed; }
      .formradio input:checked[disabled] + label::before {
        background-color: #9a9a9a;
        border: none;
        box-shadow: 0 0 0 2px #9a9a9a; }
      .formradio input:checked[disabled] + label::after {
        opacity: 0; }
  .formradio .helper {
    cursor: pointer;
    display: block;
    padding-left: 24px;
    line-height: 1.2;
    margin-bottom: 12px; }
    .formradio .helper::before, .formradio .helper::after {
      content: '';
      display: block;
      position: absolute; }

.formradio-bold input + label {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; }

.formsubform {
  padding-left: 24px; }
  .formsubform > * {
    margin-bottom: 16px; }

.icon {
  display: block;
  padding-left: 20px;
  position: relative; }
  .icon::before {
    background: center center no-repeat;
    content: '';
    display: block;
    height: 16px;
    left: 0;
    position: absolute;
    top: 0;
    width: 16px; }
  .icon[href] .icon-x-text {
    box-shadow: 0 1px 0 0 #2578a2;
    color: #2578a2;
    font-weight: bold;
    outline-offset: 4px;
    text-decoration: none;
    transition: box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1); }
    .icon[href] .icon-x-text:focus, .icon[href] .icon-x-text:hover {
      box-shadow: 0 2px 0 0 #2578a2; }

.icon-centered:before {
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0); }

.icon-file::before {
  background-image: url("images/icon-file.svg"); }

.icon-organization::before {
  background-image: url("images/icon-organization.svg"); }

.icon-price::before {
  background-image: url("images/icon-price.svg"); }

.icon-stack::before {
  background-image: url("images/icon-stack.svg"); }

.icon-back::before {
  background-image: url("images/icon-back.svg"); }

.icon-back:hover::before {
  background-image: url("images/icon-back-hover.svg"); }

.icon-next::before {
  background-image: url("images/icon-next.svg"); }

.icon-next:hover::before {
  background-image: url("images/icon-next-hover.svg"); }

.icon-chevron-right::before {
  background-image: url("images/icon-chevron-right.svg"); }

.icon-chevron-right:hover::before {
  background-image: url("images/icon-chevron-right.svg"); }

.icon-automated {
  display: block;
  padding-left: 68px;
  position: relative; }
  .icon-automated::before {
    background-image: url("images/icon-automated.svg");
    background-size: contain;
    content: '';
    display: block;
    height: 64px;
    left: 0;
    position: absolute;
    top: 0;
    width: 64px; }

.icon-download::before {
  background-image: url("images/icon-download.svg");
  height: 24px;
  width: 24px; }

.icon-download:hover::before {
  background-image: url("images/icon-download-hover.svg"); }

.icon-downloadwhite::before {
  background-image: url("images/icon-download-white.svg");
  height: 24px;
  width: 24px; }

.icon-email::before {
  background-image: url("images/icon-email.svg");
  height: 24px;
  width: 24px; }

.icon-star::before {
  background-image: url("images/icon-star.svg");
  background-size: cover;
  height: 24px;
  width: 24px; }

.icon-star-filled::before {
  background-image: url("images/icon-star-filled.svg");
  background-size: cover;
  height: 24px;
  width: 24px; }

.icon-star, .icon-star-filled {
  padding-left: 24px; }

.jumpsection {
  display: flex;
  flex-direction: column; }
  .jumpsection label {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    display: block;
    margin-bottom: 4px; }
  .jumpsection select {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    background: url("images/jumpsection.svg") calc(100% - 8px) center no-repeat #fff;
    border-radius: 2px;
    box-shadow: 0 0 0 1px #2578a2;
    color: #2578a2;
    cursor: pointer;
    display: block;
    max-width: 225px;
    outline: none;
    padding: 4px 32px 4px 8px;
    transition: background-image 600ms cubic-bezier(0.19, 1, 0.22, 1), box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1), color 600ms cubic-bezier(0.19, 1, 0.22, 1); }
    .jumpsection select:active:focus {
      box-shadow: 0 0 0 2px #004861; }
    .jumpsection select:hover {
      background-image: url("images/jumpsection-hover.svg");
      box-shadow: 0 0 0 2px #2578a2;
      color: #004861; }
    .jumpsection select:focus {
      box-shadow: 0 0 0 2px #2578a2, 0 0 8px 0 #2578a2; }

@keyframes loading-fade {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.loading {
  align-items: center;
  display: flex;
  justify-content: center; }
  .loading > * {
    animation: loading-fade 1500ms cubic-bezier(0.19, 1, 0.22, 1) infinite forwards;
    background: url("images/logo.svg") center center no-repeat;
    background-size: contain;
    color: transparent;
    display: block;
    height: 48px;
    user-select: none;
    width: 196px; }

.marketingbanner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  text-align: center; }
  .marketingbanner .marketingbanner-x-button > * {
    background-color: #493e37;
    border-color: #493e37;
    width: 100%; }
  .marketingbanner .marketingbanner-x-content {
    margin: 0 auto;
    max-width: 288px;
    padding: 0; }
    .marketingbanner .marketingbanner-x-content h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      color: #627f34;
      margin-bottom: 8px; }
      .marketingbanner .marketingbanner-x-content h1 span {
        display: block; }
    .marketingbanner .marketingbanner-x-content p {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 16px;
      max-width: 100%; }
  .marketingbanner .marketingbanner-x-image {
    margin-bottom: 16px;
    margin-right: 0; }
  @media (min-width: 768px) {
    .marketingbanner {
      flex-direction: row;
      padding: 24px 32px 32px;
      text-align: initial; }
      .marketingbanner .marketingbanner-x-button > * {
        width: initial; }
      .marketingbanner .marketingbanner-x-content {
        margin: initial;
        max-width: initial;
        padding: 36px 0 20px;
        text-align: left; }
        .marketingbanner .marketingbanner-x-content h1 {
          font-family: "museo-slab", sans-serif;
          font-size: 40px;
          font-weight: 700;
          line-height: 48px; }
        .marketingbanner .marketingbanner-x-content p {
          max-width: 240px; }
      .marketingbanner .marketingbanner-x-image {
        margin-right: 16px; } }
  @media (min-width: 1024px) {
    .marketingbanner .marketingbanner-x-content h1 {
      font-family: "museo-slab", sans-serif;
      font-size: 64px;
      font-weight: 700;
      line-height: 72px; }
    .marketingbanner .marketingbanner-x-content p {
      font-size: 24px;
      font-weight: 300;
      line-height: 32px;
      max-width: 360px; }
    .marketingbanner .marketingbanner-x-image {
      margin-right: 16px; } }

.marketingborder {
  background-color: #493e37;
  height: 8px;
  width: 100%; }

.marketingcardabout {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto 24px;
  max-width: 792px; }
  .marketingcardabout .marketingcardabout-x-image {
    margin-bottom: 24px; }
  .marketingcardabout .marketingcardabout-x-text {
    order: 1;
    text-align: center; }
    .marketingcardabout .marketingcardabout-x-text h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 16px; }
  @media (min-width: 768px) {
    .marketingcardabout {
      flex-direction: row;
      justify-content: space-between;
      padding: 0 32px; }
      .marketingcardabout:nth-of-type(even) .marketingcardabout-x-text {
        order: 0;
        padding-right: 40px; }
      .marketingcardabout:nth-of-type(odd) .marketingcardabout-x-text {
        order: 1;
        padding-left: 40px; }
      .marketingcardabout .marketingcardabout-x-image {
        margin-bottom: 0; }
      .marketingcardabout .marketingcardabout-x-text {
        order: initial;
        text-align: left; } }

.marketingfooter {
  color: #493e37;
  padding: 40px 16px;
  text-align: center; }
  .marketingfooter p {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 auto;
    max-width: 460px; }
  .marketingfooter .marketingfooter-x-image {
    margin-bottom: 24px; }

.marketingcardstep {
  margin: 0 auto 56px;
  max-width: 188px;
  padding: 16px; }
  .marketingcardstep h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px; }
  .marketingcardstep .marketingcardstep-x-image {
    margin-bottom: 24px;
    text-align: center; }
  .marketingcardstep .marketingcardstep-x-text {
    text-align: center; }
  @media (min-width: 768px) {
    .marketingcardstep {
      margin: 0 auto 8px; } }
  @media (min-width: 1024px) {
    .marketingcardstep {
      margin: 0 auto; } }

.marketingsection {
  padding: 80px 16px;
  text-align: center; }
  .marketingsection .marketingsection-x-heading {
    margin-bottom: 48px; }
    .marketingsection .marketingsection-x-heading h2 {
      font-family: "museo-slab", sans-serif;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px; }
    .marketingsection .marketingsection-x-heading p {
      margin: 8px auto 0;
      max-width: 560px; }

.dropdown {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(73, 62, 55, 0.1);
  padding-left: 8px;
  padding-right: 8px;
  position: relative; }
  .dropdown::before {
    background: #fff;
    border-radius: 2px;
    box-shadow: -1px -1px 0 0 rgba(73, 62, 55, 0.1);
    content: '';
    display: block;
    height: 16px;
    position: absolute;
    right: 8px;
    top: -8px;
    transform: rotate(45deg);
    width: 16px; }

.dropdown-left::before {
  left: 8px;
  right: auto; }

.dropdownitem {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  box-sizing: border-box;
  color: #493e37;
  display: flex;
  justify-content: space-between;
  min-width: 118px;
  padding: 8px 12px;
  transition: color 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .dropdownitem:not(:last-of-type) {
    border-bottom: 1px solid rgba(73, 62, 55, 0.1); }
  .dropdownitem .dropdownitem-x-count {
    margin-left: 16px; }
  .dropdownitem:active, .dropdownitem:focus {
    color: #004861; }
  .dropdownitem:hover {
    color: #2578a2; }

.linklist {
  display: flex; }
  .linklist p {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    text-transform: uppercase; }
    .linklist p a {
      color: #493e37;
      display: block;
      transition: color 600ms cubic-bezier(0.19, 1, 0.22, 1); }
      .linklist p a:hover {
        color: #2578a2; }
    .linklist p:not(:last-child) {
      border-right: 1px solid #493e37;
      margin-right: 8px;
      padding-right: 8px; }

.menudesktopitem {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  color: #493e37;
  display: inline-block;
  padding: 12px 16px 28px;
  position: relative;
  transition: background-color 600ms cubic-bezier(0.19, 1, 0.22, 1), color 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .menudesktopitem::after {
    background-color: #2578a2;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    opacity: 0;
    position: absolute;
    top: 40px;
    transform: translateX(-50%);
    transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
    width: calc(100% - 32px); }
  .menudesktopitem:hover, .menudesktopitem:focus {
    color: #2578a2;
    outline: none; }
    .menudesktopitem:hover::after, .menudesktopitem:focus::after {
      opacity: 1; }
  .menudesktopitem.menudesktopitem-is-active {
    border-bottom: 1px solid #fff;
    border-left: 1px solid #493e37;
    border-right: 1px solid #493e37;
    border-top: 1px solid #493e37;
    color: #2578a2;
    margin-bottom: -1px; }
    .menudesktopitem.menudesktopitem-is-active::after {
      opacity: 0; }

.menudesktopitem-noborder.menudesktopitem-is-active {
  border: none;
  margin-bottom: 0; }
  .menudesktopitem-noborder.menudesktopitem-is-active::after {
    opacity: 1; }

.menudesktoplogo {
  background-size: contain;
  color: transparent;
  height: 80px;
  position: relative;
  user-select: none;
  width: 212px; }
  .menudesktoplogo::before, .menudesktoplogo::after {
    content: '';
    display: block; }
  .menudesktoplogo::after {
    height: 100%;
    opacity: 0;
    position: absolute;
    right: 100%;
    top: 0;
    width: calc(50vw - 500px); }
  .menudesktoplogo > * {
    background: url("images/logo.svg") center center no-repeat;
    background-size: contain;
    color: transparent;
    height: 65px;
    left: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 160px; }
  .menudesktoplogo a:focus {
    outline: 1px solid #2578a2;
    outline-offset: 1px; }
  @media (min-width: 1024px) {
    .menudesktoplogo {
      height: 112px;
      width: 320px; }
      .menudesktoplogo > * {
        height: 86px;
        left: 37px;
        width: 211px; } }
  @media (min-width: 1032px) {
    .menudesktoplogo > * {
      left: 0; }
    .menudesktoplogo::after {
      opacity: 1; } }

.menumobileitem {
  background-color: #fff;
  align-items: center;
  display: flex;
  padding: 16px;
  transition: background-color 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .menumobileitem .menumobileitem-x-letter {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    align-items: center;
    background-color: #627f34;
    border-radius: 100%;
    color: #fff;
    display: flex;
    height: 40px;
    justify-content: center;
    margin-right: 16px;
    width: 40px; }
  .menumobileitem .menumobileitem-x-text {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: #493e37;
    transition: color 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .menumobileitem:hover {
    background-color: #fff; }
    .menumobileitem:hover .menumobileitem-x-text {
      color: #004861; }

.menumobileitem-profile {
  background-color: #fff; }
  .menumobileitem-profile .menumobileitem-x-text {
    color: #493e37; }

.menutrigger {
  background: #fff;
  border: 1px solid rgba(73, 62, 55, 0.1);
  border-radius: 500px;
  display: flex;
  outline: none;
  padding: 4px 32px 4px 4px;
  position: relative;
  transition: border-color 600ms cubic-bezier(0.19, 1, 0.22, 1), box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .menutrigger::before, .menutrigger::after {
    background: url("images/menutrigger.svg") center center no-repeat;
    content: '';
    display: block;
    height: 16px;
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 16px; }
  .menutrigger::after {
    background-image: url("images/menutrigger-hover.svg");
    opacity: 0; }
  .menutrigger .menutrigger-x-letter {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    align-items: center;
    background-color: #627f34;
    border-radius: 100%;
    color: #fff;
    display: flex;
    height: 24px;
    justify-content: center;
    margin-right: 8px;
    width: 24px; }
  .menutrigger .menutrigger-x-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #493e37;
    display: none;
    padding-left: 4px;
    transition: color 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .menutrigger:active:focus {
    color: #004861; }
  .menutrigger:hover, .menutrigger:focus {
    border-color: #2578a2;
    box-shadow: 0 2px 0 0 rgba(73, 62, 55, 0.2), 0 0 4px 0 rgba(73, 62, 55, 0.2); }
    .menutrigger:hover::before, .menutrigger:focus::before {
      opacity: 0; }
    .menutrigger:hover::after, .menutrigger:focus::after {
      opacity: 1; }
  .menutrigger:focus:hover .menutrigger-x-text {
    color: #004861; }
  .menutrigger:focus .menutrigger-x-text {
    color: #004861; }
  .menutrigger:hover .menutrigger-x-text {
    color: #2578a2; }
  @media (min-width: 768px) {
    .menutrigger .menutrigger-x-letter {
      margin-right: 12px; }
    .menutrigger .menutrigger-x-text {
      display: block; } }

.menutrigger-close .menutrigger-x-letter,
.menutrigger-count .menutrigger-x-letter,
.menutrigger-menu .menutrigger-x-letter,
.menutrigger-profile .menutrigger-x-letter {
  margin-right: 12px; }

.menutrigger-close .menutrigger-x-text,
.menutrigger-count .menutrigger-x-text,
.menutrigger-menu .menutrigger-x-text,
.menutrigger-profile .menutrigger-x-text {
  display: block; }

.menutrigger-count::before,
.menutrigger-profile::before {
  background-image: url("images/menutrigger-profile.svg"); }

.menutrigger-count::after,
.menutrigger-profile::after {
  background-image: url("images/menutrigger-profile-hover.svg"); }

.menutrigger-profile::before {
  background-image: url("images/menutrigger-profile-dark.svg"); }

.menutrigger-close::before {
  background-image: url("images/menutrigger-close.svg"); }

.menutrigger-close::after {
  background-image: url("images/menutrigger-close-hover.svg"); }

.menutrigger-count {
  flex-direction: row-reverse; }
  .menutrigger-count > *:not(:last-child) {
    margin-left: 64px; }

.menutrigger-big {
  justify-content: space-between;
  width: 100%; }

.message {
  background-color: #493e37;
  border-radius: 4px;
  box-sizing: border-box;
  color: #fff;
  padding: 12px 8px 12px 40px;
  position: relative; }
  .message::before {
    background: url("images/message.svg") center center no-repeat;
    content: '';
    height: 24px;
    left: 8px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 24px; }
  .message p {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px; }
  @media (min-width: 768px) {
    .message {
      padding-left: 56px; }
      .message::before {
        left: 16px; } }

.message-confirm {
  background-color: #368170; }
  .message-confirm::before {
    background-image: url("images/message-confirm.svg"); }

.message-leveled {
  background-color: #894198; }
  .message-leveled::before {
    background-image: url("images/message-leveled.svg"); }

.message-progress {
  background-color: #a76825; }
  .message-progress::before {
    background-image: url("images/message-progress.svg"); }

.message-error {
  background-color: #3C7AA2; }
  .message-error::before {
    background-image: url("images/message-error.svg"); }

.message-all {
  background-color: #4a61ac; }
  .message-all::before {
    background-image: url("images/message-all.svg"); }

.message-noicon {
  padding-left: 12px; }
  .message-noicon::before {
    display: none; }
  @media (min-width: 768px) {
    .message-noicon {
      padding-left: 12px; } }

.toast {
  align-items: center;
  background-color: #493e37;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #493e37, 0 1px 4px 0 #493e37;
  box-sizing: border-box;
  display: flex;
  overflow: hidden; }
  .toast > button {
    align-self: stretch;
    background-color: #fff;
    color: transparent;
    flex: 0 0 auto;
    overflow: hidden;
    padding: 8px;
    position: relative;
    user-select: none;
    width: 32px; }
    .toast > button::before, .toast > button::after {
      background: center center no-repeat;
      content: '';
      display: block;
      height: 16px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
      width: 16px; }
    .toast > button::before {
      background-image: url("images/toast-button.svg"); }
    .toast > button::after {
      background-image: url("images/toast-button-hover.svg");
      opacity: 0; }
    .toast > button:hover::before {
      opacity: 0; }
    .toast > button:hover::after {
      opacity: 1; }
  .toast > div {
    flex: 1; }

.toast-confirm {
  background-color: #368170;
  box-shadow: 0 0 0 1px #368170, 0 1px 4px 0 #493e37; }

.toast-leveled {
  background-color: #894198;
  box-shadow: 0 0 0 1px #894198, 0 1px 4px 0 #493e37; }

.toast-progress {
  background-color: #a76825;
  box-shadow: 0 0 0 1px #a76825, 0 1px 4px 0 #493e37; }

.toast-error {
  background-color: #3C7AA2;
  box-shadow: 0 0 0 1px #3C7AA2, 0 1px 4px 0 #493e37; }

.toast-all {
  background-color: #4a61ac;
  box-shadow: 0 0 0 1px #4a61ac, 0 1px 4px 0 #493e37; }

.pagination .pagination-x-pages {
  display: flex; }
  .pagination .pagination-x-pages > * {
    display: none;
    position: relative; }
    .pagination .pagination-x-pages > *:not(:last-child) {
      margin-right: 16px; }
    .pagination .pagination-x-pages > *:first-child, .pagination .pagination-x-pages > *:last-child {
      display: block; }
    .pagination .pagination-x-pages > *:first-child, .pagination .pagination-x-pages > *:nth-last-child(2) {
      margin-right: 32px; }
      .pagination .pagination-x-pages > *:first-child::after, .pagination .pagination-x-pages > *:nth-last-child(2)::after {
        background-color: #493e37;
        content: '';
        display: block;
        height: 16px;
        position: absolute;
        right: -16px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px; }

@media (min-width: 768px) {
  .pagination .pagination-x-pages > * {
    display: block; } }

.page {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  display: inline-block;
  transition: color 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .page[href] {
    color: #2578a2; }
    .page[href]:hover {
      color: #004861; }
  .page.page-is-active {
    color: #493e37; }
  .page.page-is-disabled {
    color: #d9dacd;
    pointer-events: none; }

.page-alt1 {
  border: 1px solid transparent;
  border-radius: 2px;
  padding: 8px; }
  .page-alt1.page-is-active {
    background-color: #493e37;
    border-color: #493e37;
    color: #fff; }
    .page-alt1.page-is-active[href]:hover {
      color: #fff; }

.progress {
  border: 1px solid #d9dacd;
  border-radius: 40px;
  display: none;
  height: 12px;
  min-width: 100px;
  padding: 2px; }
  .progress span {
    background-color: #abcd32;
    border-radius: 40px;
    display: block;
    height: 100%; }
  @media (min-width: 768px) {
    .progress {
      display: block; } }

.quicklinks {
  z-index: 3; }
  .quicklinks a,
  .quicklinks button {
    background-color: #fff;
    color: #493e37;
    display: block;
    left: 8px;
    opacity: 0;
    position: fixed;
    padding: 8px;
    pointer-events: none;
    top: 8px;
    transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1); }
    .quicklinks a:focus,
    .quicklinks button:focus {
      opacity: 1;
      pointer-events: auto; }

.sidebarnavitem {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #2578a2;
  display: block;
  padding: 8px 16px;
  position: relative;
  transition: background-color 600ms cubic-bezier(0.19, 1, 0.22, 1), color 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .sidebarnavitem::after {
    background-color: rgba(73, 62, 55, 0.1);
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
    width: calc(100% - 16px); }
  .sidebarnavitem .sidebarnavitem-x-subtext {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    display: block; }
  .sidebarnavitem:hover, .sidebarnavitem.sidebarnavitem-is-active {
    background-color: #fff;
    color: #004861; }
  .sidebarnavitem.sidebarnavitem-is-active::after {
    opacity: 0; }

.small {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px; }

.snippet > a {
  display: inline-block;
  margin-top: 8px; }

.snippet .snippet-x-heading {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px; }

.snippet-large .snippet-x-heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px; }

@media (min-width: 768px) {
  .snippet-large p {
    font-size: 24px;
    font-weight: 300;
    line-height: 32px; }
  .snippet-large .snippet-x-heading {
    font-family: "museo-slab", sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px; }
    .snippet-large .snippet-x-heading:not(:last-child) {
      margin-bottom: 8px; } }

.statuscount {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  background-color: #493e37;
  border-radius: 500px;
  color: #fff;
  display: inline-block;
  padding: 4px 9px; }

.statuscount-confirm {
  background-color: #368170; }

.statuscount-leveled {
  background-color: #894198; }

.statuscount-progress {
  background-color: #a76825; }

.statuscount-error {
  background-color: #3C7AA2; }

.statuscount-all {
  background-color: #4a61ac; }

.statuscountnumber {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background-color: transparent; }

.statuscountnumber-confirm {
  color: #368170; }

.statuscountnumber-leveled {
  color: #894198; }

.statuscountnumber-progress {
  color: #a76825; }

.statuscountnumber-error {
  color: #3C7AA2; }

.statuscountnumber-all {
  color: #4a61ac; }

.statusfilter {
  align-items: center;
  display: flex;
  padding-bottom: 16px;
  position: relative; }
  .statusfilter::after {
    background-color: #004861;
    bottom: 0;
    content: '';
    display: block;
    height: 4px;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 100%; }
  .statusfilter .statusfilter-x-count {
    display: none;
    margin-left: 8px; }
  .statusfilter .statusfilter-x-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: #493e37;
    transition: color 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .statusfilter:hover .statusfilter-x-text, .statusfilter:focus .statusfilter-x-text, .statusfilter.statusfilter-is-active .statusfilter-x-text {
    color: #2578a2; }
  .statusfilter:focus {
    outline: none; }
  .statusfilter.statusfilter-is-active .statusfilter-x-text {
    color: #004861; }
  .statusfilter.statusfilter-is-active::after {
    opacity: 1; }
  @media (min-width: 768px) {
    .statusfilter .statusfilter-x-count {
      display: block; } }

@media (min-width: 768px) {
  .statusfilter-nocount .statusfilter-x-count {
    display: none; } }

.statusfilterlarge {
  padding-bottom: 24px;
  position: relative;
  z-index: 2; }
  .statusfilterlarge::after {
    background: url("images/statusfilterlarge-active.svg") center center no-repeat;
    bottom: 0;
    content: '';
    display: block;
    height: 12px;
    left: 50%;
    opacity: 0;
    position: absolute;
    transform: translateX(-50%);
    transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 20px; }
  .statusfilterlarge .statusfilter-x-count,
  .statusfilterlarge .statusfilter-x-text {
    transition: color 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  .statusfilterlarge .statusfilterlarge-x-container {
    align-items: center;
    background-color: #fff;
    border: 1px solid #f1f2e7;
    border-radius: 100%;
    box-shadow: 0 2px 0 0 #f1f2e7, 0 0 4px 0 #f1f2e7;
    display: flex;
    flex-direction: column;
    height: 112px;
    justify-content: center;
    transition: background-color 600ms cubic-bezier(0.19, 1, 0.22, 1), box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 112px; }
  .statusfilterlarge .statusfilterlarge-x-count {
    font-family: "museo-slab", sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: #493e37;
    display: block;
    margin-bottom: 4px; }
  .statusfilterlarge .statusfilterlarge-x-text {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: #2578a2; }
  .statusfilterlarge:hover .statusfilterlarge-x-container, .statusfilterlarge:focus .statusfilterlarge-x-container {
    border-color: #fff;
    box-shadow: 0 2px 0 0 #f1f2e7, 0 0 4px 0 #f1f2e7; }
  .statusfilterlarge:focus {
    outline: none; }
  .statusfilterlarge.statusfilterlarge-is-active::after {
    opacity: 1; }
  .statusfilterlarge.statusfilterlarge-is-active .statusfilterlarge-x-container {
    background-color: #493e37;
    box-shadow: none; }
  .statusfilterlarge.statusfilterlarge-is-active .statusfilterlarge-x-count,
  .statusfilterlarge.statusfilterlarge-is-active .statusfilterlarge-x-text {
    color: #fff; }
  .statusfilterlarge.statusfilterlarge-is-active:hover .statusfilterlarge-x-container, .statusfilterlarge.statusfilterlarge-is-active:focus .statusfilterlarge-x-container {
    border-color: #493e37;
    box-shadow: 0 0 8px 0 #493e37; }

.statusfilterlarge-square {
  flex: 1 1 100%;
  padding-bottom: 0; }
  .statusfilterlarge-square:after {
    display: none; }
  .statusfilterlarge-square:not(:last-of-type) .statusfilterlarge-x-container:after {
    background-color: #f1f2e7;
    content: '';
    display: none;
    height: 80px;
    position: absolute;
    right: 0;
    width: 1px; }
  .statusfilterlarge-square .statusfilterlarge-x-container {
    border: none;
    border-radius: 0;
    box-shadow: none;
    flex-direction: row-reverse;
    height: initial;
    justify-content: space-between;
    padding: 0 16px;
    position: relative;
    width: initial; }
  .statusfilterlarge-square .statusfilterlarge-x-count {
    font-size: 24px;
    margin-bottom: 0; }
  .statusfilterlarge-square .statusfilterlarge-x-text {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    color: #493e37;
    margin-bottom: 8px;
    text-align: center; }
  .statusfilterlarge-square:hover .statusfilterlarge-x-container, .statusfilterlarge-square:focus .statusfilterlarge-x-container {
    border-color: none;
    box-shadow: none; }
  @media (min-width: 768px) {
    .statusfilterlarge-square:not(:last-of-type) .statusfilterlarge-x-container:after {
      display: block; }
    .statusfilterlarge-square .statusfilterlarge-x-container {
      border: none;
      flex-direction: column-reverse;
      height: 112px;
      justify-content: center; } }
  @media (min-width: 1024px) {
    .statusfilterlarge-square .statusfilterlarge-x-count {
      font-family: "museo-slab", sans-serif;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px; } }

.statusfilterlarge-confirm .statusfilterlarge-x-count {
  color: #368170; }

.statusfilterlarge-confirm:hover .statusfilterlarge-x-container, .statusfilterlarge-confirm:focus .statusfilterlarge-x-container {
  border-color: #368170;
  box-shadow: 0 2px 0 0 #368170, 0 0 4px 0 #368170; }

.statusfilterlarge-confirm.statusfilterlarge-is-active .statusfilterlarge-x-container {
  background-color: #368170; }

.statusfilterlarge-confirm.statusfilterlarge-is-active:hover .statusfilterlarge-x-container, .statusfilterlarge-confirm.statusfilterlarge-is-active:focus .statusfilterlarge-x-container {
  border-color: #368170;
  box-shadow: 0 0 8px 0 #368170; }

.statusfilterlarge-square-confirm .statusfilterlarge-x-count {
  color: #368170; }

.statusfilterlarge-square-confirm:hover .statusfilterlarge-x-container, .statusfilterlarge-square-confirm:focus .statusfilterlarge-x-container {
  border-color: none;
  box-shadow: none; }

.statusfilterlarge-leveled .statusfilterlarge-x-count {
  color: #894198; }

.statusfilterlarge-leveled:hover .statusfilterlarge-x-container, .statusfilterlarge-leveled:focus .statusfilterlarge-x-container {
  border-color: #894198;
  box-shadow: 0 2px 0 0 #894198, 0 0 4px 0 #894198; }

.statusfilterlarge-leveled.statusfilterlarge-is-active .statusfilterlarge-x-container {
  background-color: #894198; }

.statusfilterlarge-leveled.statusfilterlarge-is-active:hover .statusfilterlarge-x-container, .statusfilterlarge-leveled.statusfilterlarge-is-active:focus .statusfilterlarge-x-container {
  border-color: #894198;
  box-shadow: 0 0 8px 0 #894198; }

.statusfilterlarge-square-leveled .statusfilterlarge-x-count {
  color: #894198; }

.statusfilterlarge-square-leveled:hover .statusfilterlarge-x-container, .statusfilterlarge-square-leveled:focus .statusfilterlarge-x-container {
  border-color: none;
  box-shadow: none; }

.statusfilterlarge-progress .statusfilterlarge-x-count {
  color: #a76825; }

.statusfilterlarge-progress:hover .statusfilterlarge-x-container, .statusfilterlarge-progress:focus .statusfilterlarge-x-container {
  border-color: #a76825;
  box-shadow: 0 2px 0 0 #a76825, 0 0 4px 0 #a76825; }

.statusfilterlarge-progress.statusfilterlarge-is-active .statusfilterlarge-x-container {
  background-color: #a76825; }

.statusfilterlarge-progress.statusfilterlarge-is-active:hover .statusfilterlarge-x-container, .statusfilterlarge-progress.statusfilterlarge-is-active:focus .statusfilterlarge-x-container {
  border-color: #a76825;
  box-shadow: 0 0 8px 0 #a76825; }

.statusfilterlarge-square-progress .statusfilterlarge-x-count {
  color: #a76825; }

.statusfilterlarge-square-progress:hover .statusfilterlarge-x-container, .statusfilterlarge-square-progress:focus .statusfilterlarge-x-container {
  border-color: none;
  box-shadow: none; }

.statusfilterlarge-error .statusfilterlarge-x-count {
  color: #3C7AA2; }

.statusfilterlarge-error:hover .statusfilterlarge-x-container, .statusfilterlarge-error:focus .statusfilterlarge-x-container {
  border-color: #3C7AA2;
  box-shadow: 0 2px 0 0 #3C7AA2, 0 0 4px 0 #3C7AA2; }

.statusfilterlarge-error.statusfilterlarge-is-active .statusfilterlarge-x-container {
  background-color: #3C7AA2; }

.statusfilterlarge-error.statusfilterlarge-is-active:hover .statusfilterlarge-x-container, .statusfilterlarge-error.statusfilterlarge-is-active:focus .statusfilterlarge-x-container {
  border-color: #3C7AA2;
  box-shadow: 0 0 8px 0 #3C7AA2; }

.statusfilterlarge-square-error .statusfilterlarge-x-count {
  color: #3C7AA2; }

.statusfilterlarge-square-error:hover .statusfilterlarge-x-container, .statusfilterlarge-square-error:focus .statusfilterlarge-x-container {
  border-color: none;
  box-shadow: none; }

.statusfilterlarge-all .statusfilterlarge-x-count {
  color: #4a61ac; }

.statusfilterlarge-all:hover .statusfilterlarge-x-container, .statusfilterlarge-all:focus .statusfilterlarge-x-container {
  border-color: #4a61ac;
  box-shadow: 0 2px 0 0 #4a61ac, 0 0 4px 0 #4a61ac; }

.statusfilterlarge-all.statusfilterlarge-is-active .statusfilterlarge-x-container {
  background-color: #4a61ac; }

.statusfilterlarge-all.statusfilterlarge-is-active:hover .statusfilterlarge-x-container, .statusfilterlarge-all.statusfilterlarge-is-active:focus .statusfilterlarge-x-container {
  border-color: #4a61ac;
  box-shadow: 0 0 8px 0 #4a61ac; }

.statusfilterlarge-square-all .statusfilterlarge-x-count {
  color: #4a61ac; }

.statusfilterlarge-square-all:hover .statusfilterlarge-x-container, .statusfilterlarge-square-all:focus .statusfilterlarge-x-container {
  border-color: none;
  box-shadow: none; }

.statusfilterslarge {
  border-bottom: 8px solid #493e37;
  display: none;
  margin-top: 32px;
  overflow-y: auto; }
  .statusfilterslarge > *:not(:last-child) {
    margin-right: 24px; }
  @media (min-width: 1024px) {
    .statusfilterslarge {
      display: flex; } }

.statusfilterslarge-square {
  border-bottom: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  overflow-y: initial; }
  .statusfilterslarge-square > *:not(:last-child) {
    margin-right: 0; }
  @media (min-width: 768px) {
    .statusfilterslarge-square {
      flex-direction: row; } }

.statusfilters {
  display: flex;
  padding-top: 12px; }
  .statusfilters .statusfilters-x-filter {
    padding-top: 4px;
    position: relative; }
    .statusfilters .statusfilters-x-filter:not(:last-of-type) {
      margin-right: 16px;
      padding-right: 16px; }
  @media (min-width: 768px) {
    .statusfilters .statusfilters-x-filter:not(:last-of-type) {
      margin-right: 24px;
      padding-right: 25px; }
      .statusfilters .statusfilters-x-filter:not(:last-of-type)::after {
        background-color: #f1f2e7;
        content: '';
        display: block;
        height: 32px;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px; } }

.statusfilters-mobilehide {
  display: none; }
  @media (min-width: 768px) {
    .statusfilters-mobilehide {
      display: flex; } }

.statusicon {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  display: block;
  padding-left: 12px;
  position: relative; }
  .statusicon::before {
    background-color: #493e37;
    border-radius: 100%;
    content: '';
    display: block;
    height: 8px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 8px; }

.statusicon-confirm::before {
  background-color: #368170; }

.statusicon-inline-confirm {
  background-color: #368170; }

.statusicon-leveled::before {
  background-color: #823D90; }

.statusicon-inline-leveled {
  background-color: #823D90; }

.statusicon-progress::before {
  background-color: #b47324; }

.statusicon-inline-progress {
  background-color: #b47324; }

.statusicon-error::before {
  background-color: #2578A2; }

.statusicon-inline-error {
  background-color: #2578A2; }

.statusicon-all::before {
  background-color: #26427a; }

.statusicon-inline-all {
  background-color: #26427a; }

.statusicon-group {
  display: flex;
  align-items: baseline; }
  .statusicon-group .statusicon-inline {
    border-radius: 100%;
    margin: 4px;
    flex-shrink: 0;
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px; }
  .statusicon-group .status {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px; }

.statustag {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  background-color: #493e37;
  color: #fff;
  display: inline-block;
  padding: 4px 8px;
  text-align: center; }

.statustag-confirm {
  background-color: #368170; }

.statustag-leveled {
  background-color: #894198; }

.statustag-progress {
  background-color: #a76825; }

.statustag-error {
  background-color: #3C7AA2; }

.statustag-all {
  background-color: #4a61ac; }

.submissionstate {
  margin-bottom: 16px; }
  .submissionstate .submissionstate-x-content {
    align-items: flex-start;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    padding: 32px; }
    @media (max-width: 768px) {
      .submissionstate .submissionstate-x-content {
        padding: 8px;
        flex-wrap: wrap; } }
    .submissionstate .submissionstate-x-content h1 {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      font-size: 32px;
      color: #fff;
      margin-bottom: 8px;
      font-family: "museo-slab", sans-serif;
      margin-bottom: 16px; }
    .submissionstate .submissionstate-x-content p {
      color: #fff;
      margin-bottom: 8px; }
    .submissionstate .submissionstate-x-content a {
      text-decoration: underline;
      color: white; }
      .submissionstate .submissionstate-x-content a:hover {
        text-decoration: none; }
    .submissionstate .submissionstate-x-content .submissionstate-text-content {
      padding: 32px; }
    .submissionstate .submissionstate-x-content .submissionstate-stars {
      display: flex;
      flex-direction: row;
      gap: 8px;
      min-height: 24px;
      padding: 24px 0 8px 0; }
    .submissionstate .submissionstate-x-content .submissionstate-badge {
      padding: 12px 8px;
      background: #f7f7f7;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-radius: 4px;
      box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.17), 0 1px 9px 0 rgba(0, 0, 0, 0.07); }
      @media (max-width: 768px) {
        .submissionstate .submissionstate-x-content .submissionstate-badge {
          margin: auto; } }
      .submissionstate .submissionstate-x-content .submissionstate-badge p,
      .submissionstate .submissionstate-x-content .submissionstate-badge h1,
      .submissionstate .submissionstate-x-content .submissionstate-badge h2,
      .submissionstate .submissionstate-x-content .submissionstate-badge h3,
      .submissionstate .submissionstate-x-content .submissionstate-badge h4 {
        color: #493e37; }
      .submissionstate .submissionstate-x-content .submissionstate-badge .submissionsstate-badge-body {
        border: 1px solid #C4C4C4;
        padding: 21px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        position: relative;
        z-index: 4;
        gap: 4px; }
        .submissionstate .submissionstate-x-content .submissionstate-badge .submissionsstate-badge-body img {
          margin-bottom: 8px; }
        .submissionstate .submissionstate-x-content .submissionstate-badge .submissionsstate-badge-body h3 > *, .submissionstate .submissionstate-x-content .submissionstate-badge .submissionsstate-badge-body h4 {
          font-weight: bold; }
        .submissionstate .submissionstate-x-content .submissionstate-badge .submissionsstate-badge-body p {
          font-size: 12px;
          margin: 0; }
        .submissionstate .submissionstate-x-content .submissionstate-badge .submissionsstate-badge-body a {
          color: #2578a2; }
          .submissionstate .submissionstate-x-content .submissionstate-badge .submissionsstate-badge-body a:hover {
            text-decoration: underline; }
        .submissionstate .submissionstate-x-content .submissionstate-badge .submissionsstate-badge-body .submissionsstate-badge-overlap-border {
          border: 1px solid #C4C4C4;
          position: absolute;
          pointer-events: none;
          background: none;
          top: -5px;
          right: 3px;
          height: calc(100% + 8px);
          width: calc(100% - 8px); }
      .submissionstate .submissionstate-x-content .submissionstate-badge .submissionsstate-badge-footer {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .submissionstate .submissionstate-x-content .submissionstate-badge .submissionsstate-badge-footer .submissionsstate-badge-share-buttons {
          display: flex; }
    .submissionstate .submissionstate-x-content .submissionstate-x-action {
      margin-top: 16px;
      margin-bottom: 16px; }
      .submissionstate .submissionstate-x-content .submissionstate-x-action button {
        margin-right: 16px;
        padding: 12px 16px;
        border: none; }
  .submissionstate .submissionstate-x-action {
    display: flex;
    flex-direction: column; }
    .submissionstate .submissionstate-x-action button {
      order: 1;
      margin-right: 8px; }
    .submissionstate .submissionstate-x-action p {
      font-style: italic;
      margin-top: 8px;
      order: 2; }
  @media (min-width: 768px) {
    .submissionstate {
      margin-bottom: 24px; }
      .submissionstate .submissionstate-x-content {
        align-items: center;
        flex-direction: row; }
      .submissionstate .submissionstate-x-action {
        align-items: center;
        display: flex;
        flex-direction: row; }
        .submissionstate .submissionstate-x-action p {
          margin-right: 8px;
          text-align: right; } }

.submissionstate-confirm .submissionstate-x-content {
  background-color: #368170;
  background-size: cover;
  background-blend-mode: multiply; }
  @media (min-width: 768px) {
    .submissionstate-confirm .submissionstate-x-content {
      background-image: url("images/bg-dotted.png"); } }

.submissionstate-leveled .submissionstate-x-content {
  background-color: #894198;
  background-size: cover;
  background-blend-mode: multiply; }
  @media (min-width: 768px) {
    .submissionstate-leveled .submissionstate-x-content {
      background-image: url("images/bg-dotted.png"); } }

.submissionstate-progress .submissionstate-x-content {
  background-color: #a76825;
  background-size: cover;
  background-blend-mode: multiply; }
  @media (min-width: 768px) {
    .submissionstate-progress .submissionstate-x-content {
      background-image: url("images/bg-dotted.png"); } }

.submissionstate-error .submissionstate-x-content {
  background-color: #3C7AA2;
  background-size: cover;
  background-blend-mode: multiply; }
  @media (min-width: 768px) {
    .submissionstate-error .submissionstate-x-content {
      background-image: url("images/bg-dotted.png"); } }

.submissionstate-all .submissionstate-x-content {
  background-color: #4a61ac;
  background-size: cover;
  background-blend-mode: multiply; }
  @media (min-width: 768px) {
    .submissionstate-all .submissionstate-x-content {
      background-image: url("images/bg-dotted.png"); } }

.explore-more {
  margin-bottom: 16px;
  align-items: center;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 16px;
  justify-content: space-between;
  padding: 16px;
  border: #4398CF 10px solid;
  border-radius: 4px;
  margin: 0 32px;
  background-color: #2578A2; }
  @media (min-width: 768px) {
    .explore-more {
      padding: 32px; } }
  .explore-more h1 {
    font-size: 32px;
    font-family: 'museo-sans', sans-serif;
    color: white;
    font-weight: bold; }
  .explore-more .button-shadow-container {
    position: relative;
    display: inline-block;
    width: 480px; }
    @media (min-width: 768px) {
      .explore-more .button-shadow-container {
        height: 45px; } }
  @media (min-width: 768px) {
    .explore-more .button-shadow {
      transform: skew(-30deg, 0deg);
      position: absolute;
      bottom: -32px;
      left: -16px;
      width: 99%;
      background: rgba(0, 0, 0, 0.18);
      height: 80%; } }
  .explore-more a.button {
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 4px; }
    @media (min-width: 768px) {
      .explore-more a.button {
        position: absolute; } }
    .explore-more a.button span.icon::before {
      position: relative !important;
      color: #2578a2; }
    .explore-more a.button span.icon {
      filter: invert(40%) sepia(26%) saturate(1139%) hue-rotate(156deg) brightness(96%) contrast(98%); }
    .explore-more a.button:focus {
      color: #fff;
      background-color: #022C42;
      border-color: #2578a2; }
    .explore-more a.button:hover {
      color: #fff;
      background-color: #022C42;
      border-color: #2578a2; }

app-previous-submissions-table {
  padding: 0 !important; }

.submissiontable {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  text-align: left;
  margin: 24px;
  margin-top: 0; }
  .submissiontable caption {
    background-color: #493e37;
    border-radius: 2px;
    color: #fff;
    max-width: 150px;
    padding: 8px !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    white-space: nowrap;
    margin-bottom: 24px; }
  .submissiontable a {
    box-shadow: 0 1px 0 0 #2578a2;
    color: #2578a2;
    font-weight: bold;
    outline-offset: 4px;
    text-decoration: none;
    transition: box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1);
    font-weight: bold;
    color: white !important;
    box-shadow: none; }
    .submissiontable a:focus, .submissiontable a:hover {
      box-shadow: 0 2px 0 0 #2578a2; }
  .submissiontable a:hover {
    box-shadow: none; }
  .submissiontable tr {
    border-bottom: 1px solid #f7f7f7;
    margin-bottom: 8px; }
    .submissiontable tr td,
    .submissiontable tr th {
      padding: 8px;
      vertical-align: middle; }
      .submissiontable tr td >,
      .submissiontable tr th > {
        width: 1px;
        white-space: nowrap; }
      .submissiontable tr td:first-child,
      .submissiontable tr th:first-child {
        font-weight: bold; }
  .submissiontable thead td,
  .submissiontable thead th {
    border-bottom: 1px solid #493e37;
    padding-bottom: 8px; }
  .submissiontable .fs-italic {
    font-style: italic !important; }

.table {
  overflow-x: auto; }
  .table td,
  .table th {
    padding: 16px 12px; }
  .table table {
    border-collapse: collapse;
    width: 100%; }
  .table tbody td,
  .table tbody th {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    background-color: #fff;
    padding-bottom: 24px;
    padding-top: 24px; }
  .table tbody tr:not(:last-child) td,
  .table tbody tr:not(:last-child) th {
    border-bottom: 1px solid #f1f2e7; }
  .table thead td {
    background-color: #493e37; }

.tableattachment table {
  width: 100%; }

.tableattachment thead tr {
  border-bottom: none; }

.tableattachment tr {
  border-bottom: 1px solid #d9dacd; }

.tableattachment td {
  display: block; }
  .tableattachment td:first-of-type,
  .tableattachment td a {
    color: #2578a2;
    padding-top: 8px; }
    .tableattachment td:first-of-type[href],
    .tableattachment td a[href] {
      box-shadow: 0 1px 0 0 #2578a2;
      color: #2578a2;
      font-weight: bold;
      outline-offset: 4px;
      text-decoration: none;
      transition: box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1); }
      .tableattachment td:first-of-type[href]:focus, .tableattachment td:first-of-type[href]:hover,
      .tableattachment td a[href]:focus,
      .tableattachment td a[href]:hover {
        box-shadow: 0 2px 0 0 #2578a2; }
  .tableattachment td:last-of-type {
    padding: 8px 0; }

.tableattachment .tableattachment-x-progress td:first-of-type {
  color: initial; }

@media (min-width: 768px) {
  .tableattachment td {
    display: table-cell;
    padding: 8px 0; }
    .tableattachment td:not(:first-of-type) {
      text-align: right; } }

.tableattachment-dark {
  border: 1px solid #d9dacd;
  padding: 8px 16px; }
  .tableattachment-dark td {
    padding: 0 16px; }
    .tableattachment-dark td:first-of-type {
      font-weight: 700; }
    .tableattachment-dark td:last-of-type {
      padding: 8px 16px; }
  .tableattachment-dark tr:last-of-type {
    border-bottom: none; }

.tabledetail td,
.tabledetail th {
  padding-bottom: 32px;
  text-align: left;
  vertical-align: top; }

.tabledetail p:not(:last-child) {
  margin-bottom: 16px; }

.tabledetail td {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px; }

.tabledetail th {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  width: 80px; }

.tablehistory {
  border-collapse: initial;
  margin-top: 8px; }
  .tablehistory td {
    padding: 4px 0; }
    .tablehistory td:first-of-type {
      width: 90px; }

.tablehistory-big tr {
  display: flex;
  flex-direction: column; }
  .tablehistory-big tr:not(:last-of-type) {
    margin-bottom: 8px; }

.tablehistory-big td {
  padding: 0; }
  .tablehistory-big td:first-of-type {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; }

@media (min-width: 768px) {
  .tablehistory-big tr {
    display: table-row; }
    .tablehistory-big tr:not(:last-of-type) {
      margin-bottom: 0; }
  .tablehistory-big td:first-of-type {
    width: 112px; } }

.tablesort {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: #fff;
  padding-right: 12px;
  position: relative;
  text-align: left; }
  .tablesort::before, .tablesort::after,
  .tablesort .tablesort-x-text::after {
    background: center center no-repeat;
    content: '';
    display: block;
    height: 14px;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 8px; }
  .tablesort::before {
    background-image: url("images/tablesort-ascending.svg"); }
  .tablesort::after {
    background-image: url("images/tablesort-descending.svg"); }
  .tablesort .tablesort-x-text::after {
    background-image: url("images/tablesort.svg");
    opacity: 1; }
  .tablesort.tablesort-is-ascending .tablesort-x-text::after, .tablesort.tablesort-is-descending .tablesort-x-text::after {
    opacity: 0; }
  .tablesort.tablesort-is-ascending::before {
    opacity: 1; }
  .tablesort.tablesort-is-descending::after {
    opacity: 1; }

.textblock > *:not([class])::before {
  clear: both;
  content: '';
  display: table; }

.textblock > *:not([class]) a {
  box-shadow: 0 1px 0 0 #2578a2;
  color: #2578a2;
  font-weight: bold;
  outline-offset: 4px;
  text-decoration: none;
  transition: box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1);
  font-weight: bold;
  word-break: break-word; }
  .textblock > *:not([class]) a:focus, .textblock > *:not([class]) a:hover {
    box-shadow: 0 2px 0 0 #2578a2; }

.textblock > *:not([class]) em {
  font-style: italic; }

.textblock > *:not([class]) img {
  border-radius: 4px;
  display: inline-block;
  float: left;
  margin-bottom: 8px;
  margin-right: 16px; }

.textblock > *:not([class]) small {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px; }

.textblock > *:not([class]) strong {
  font-weight: bold; }

.textblock > h3,
.textblock > h4,
.textblock > h5,
.textblock > h6,
.textblock > ol,
.textblock > p,
.textblock > ul {
  max-width: 560px; }

.textblock > ol,
.textblock > ul {
  margin-left: 16px;
  margin-top: 8px;
  padding-left: 1.1em; }
  .textblock > ol:not(:last-child),
  .textblock > ul:not(:last-child) {
    margin-bottom: 16px; }
  .textblock > ol ol,
  .textblock > ol ul,
  .textblock > ol p,
  .textblock > ul ol,
  .textblock > ul ul,
  .textblock > ul p {
    margin-bottom: 16px;
    margin-top: 8px; }
    .textblock > ol ol + *,
    .textblock > ol ul + *,
    .textblock > ol p + *,
    .textblock > ul ol + *,
    .textblock > ul ul + *,
    .textblock > ul p + * {
      margin-bottom: 24px; }
    .textblock > ol ol li,
    .textblock > ol ul li,
    .textblock > ol p li,
    .textblock > ul ol li,
    .textblock > ul ul li,
    .textblock > ul p li {
      list-style-position: inside;
      padding-left: 0; }
  .textblock > ol ol,
  .textblock > ol ul,
  .textblock > ul ol,
  .textblock > ul ul {
    padding-left: 8px; }
  .textblock > ol li,
  .textblock > ul li {
    list-style-position: outside;
    padding-left: 8px; }
    .textblock > ol li:not(:last-child),
    .textblock > ul li:not(:last-child) {
      margin-bottom: 8px; }

.textblock > button {
  margin-bottom: 8px;
  margin-top: 16px; }

.textblock > h1 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 24px; }
  .textblock > h1 + h2 {
    margin-top: 24px; }

.textblock > h2 {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 24px;
  text-transform: uppercase;
  white-space: nowrap; }
  .textblock > h2 span {
    background-color: #493e37;
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    padding: 8px; }
  .textblock > h2 + h3 {
    margin-top: 16px; }

.textblock > h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
  margin-top: 24px; }
  .textblock > h3 span {
    display: block;
    margin-bottom: 16px; }

.textblock > h4 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
  margin-top: 16px; }

.textblock > h5 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; }
  .textblock > h5 + p {
    margin-top: 0; }

.textblock > ol li {
  list-style-type: decimal; }

.textblock > ol ol > li {
  list-style-type: lower-latin; }

.textblock > ol ul > li {
  list-style-type: disc; }

.textblock > p {
  margin-bottom: 16px;
  margin-top: 8px; }

.textblock > ul li {
  list-style: circle; }

.textblock > ul ul > li {
  list-style-type: square; }

.textblock > ul ol > li {
  list-style-type: decimal; }

@media (min-width: 768px) {
  .textblock > button {
    margin-top: 24px; }
  .textblock > h1 {
    font-family: "museo-slab", sans-serif;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    margin-bottom: 32px; }
    .textblock > h1 + h2 {
      margin-top: 32px; }
  .textblock > h2 {
    margin-bottom: 24px; }
    .textblock > h2 + h3 {
      margin-top: 16px; }
  .textblock > h4 {
    margin-top: 24px; } }

.textbordered {
  font-family: "museo-slab", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
  border: 1px dashed #000;
  width: 130px;
  height: 130px; }

.textinfo {
  max-width: 538px; }
  .textinfo button:not(.button) {
    text-decoration: underline; }
  .textinfo fieldset {
    margin-bottom: 24px; }
    .textinfo fieldset > *:not(:last-of-type) {
      margin-bottom: 16px; }
  .textinfo .textinfo-x-header {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px; }
    .textinfo .textinfo-x-header > * {
      align-items: center;
      display: flex; }
    .textinfo .textinfo-x-header button:not([class]) {
      box-shadow: 0 1px 0 0 #2578a2;
      color: #2578a2;
      font-weight: bold;
      outline-offset: 4px;
      text-decoration: none;
      transition: box-shadow 600ms cubic-bezier(0.19, 1, 0.22, 1);
      font-size: 12px;
      font-weight: 700;
      line-height: 16px; }
      .textinfo .textinfo-x-header button:not([class]):focus, .textinfo .textinfo-x-header button:not([class]):hover {
        box-shadow: 0 2px 0 0 #2578a2; }
  .textinfo .textinfo-x-content h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0; }
  .textinfo .textinfo-x-content p {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px; }
    .textinfo .textinfo-x-content p:not(:last-of-type), .textinfo .textinfo-x-content p:first-of-type {
      margin-bottom: 16px; }
  .textinfo .textinfo-x-content .textinfo-small {
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    margin-bottom: 0 !important; }
  .textinfo .textinfo-x-content .textinfo-mb {
    margin-bottom: 16px !important; }
  .textinfo .textinfo-x-content .textinfo-small-bold {
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 0 !important; }
  .textinfo .textinfo-x-hidden {
    left: 0;
    margin-top: 16px;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    visibility: hidden;
    display: none; }
    .textinfo .textinfo-x-hidden > * {
      margin-bottom: 16px; }
      .textinfo .textinfo-x-hidden > *:only-of-type, .textinfo .textinfo-x-hidden > *:last-of-type {
        margin-bottom: 0; }
  .textinfo .textinfo-x-section {
    border-bottom: 1px solid #493e37;
    margin-bottom: 16px;
    padding-bottom: 16px; }
    .textinfo .textinfo-x-section h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 16px; }
      .textinfo .textinfo-x-section h3 span {
        font-size: 24px;
        font-weight: 300;
        line-height: 32px; }
    .textinfo .textinfo-x-section:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0; }
    .textinfo .textinfo-x-section:only-of-type {
      margin-bottom: 0; }
  .textinfo.textinfo-is-active .textinfo-x-hidden {
    left: auto;
    margin-top: 16px;
    opacity: 1;
    pointer-events: auto;
    position: relative;
    top: auto;
    visibility: visible;
    display: block;
    transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1); }
  @media (min-width: 768px) {
    .textinfo .textinfo-x-header {
      align-items: center;
      display: flex; } }

.textinfo-expanded {
  max-width: 100%; }
  .textinfo-expanded:not(:last-of-type) {
    margin-bottom: 16px; }
  .textinfo-expanded .textinfo-x-header {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    justify-content: space-between;
    margin-bottom: 8px; }
    .textinfo-expanded .textinfo-x-header > * {
      display: block; }
    .textinfo-expanded .textinfo-x-header button {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px; }
      .textinfo-expanded .textinfo-x-header button:hover {
        color: #fff; }
  .textinfo-expanded .textinfo-x-content {
    max-width: 538px; }
    .textinfo-expanded .textinfo-x-content h4 {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px; }
  .textinfo-expanded .textinfo-x-contentbig {
    max-width: 100%; }
  .textinfo-expanded .textinfo-x-comment h4,
  .textinfo-expanded .textinfo-x-comment label {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px; }
  .textinfo-expanded .textinfo-x-comment p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px; }
  .textinfo-expanded .textinfo-x-headbutton {
    display: flex;
    justify-content: space-between;
    max-width: 100%; }
    .textinfo-expanded .textinfo-x-headbutton h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
      flex: 1 1;
      margin-bottom: 16px;
      max-width: 538px; }
      .textinfo-expanded .textinfo-x-headbutton h3 span {
        font-size: 24px;
        font-weight: 300;
        line-height: 32px; }
    .textinfo-expanded .textinfo-x-headbutton .textinfo-x-buttonmediabig {
      display: none; }
  .textinfo-expanded .textinfo-x-buttonmediasmall {
    display: block;
    margin-bottom: 16px; }
  @media (min-width: 768px) {
    .textinfo-expanded .textinfo-x-headbutton .textinfo-x-buttonmediabig {
      display: block; }
    .textinfo-expanded .textinfo-x-buttonmediasmall {
      display: none; } }

.textinfo-bigheader h5 {
  font-family: "museo-slab", sans-serif;
  font-size: 64px;
  font-weight: 700;
  line-height: 72px; }

.textinfo-bigheader p {
  font-size: 24px;
  font-weight: 300;
  line-height: 32px; }

.textinfo-outer {
  padding: 16px; }
  .textinfo-outer fieldset {
    margin: 0; }
  .textinfo-outer .textinfo-x-hidden {
    margin-top: 0; }
  .textinfo-outer .textinfo-x-section:not(:last-of-type) {
    border-bottom: 1px solid #a76825; }

.textinfo-outerblue .textinfo-x-section:not(:last-of-type) {
  border-bottom: 1px solid #2578a2; }

.textmessage {
  text-align: center; }
  .textmessage a {
    text-decoration: underline; }
  .textmessage p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px; }
    .textmessage p a {
      color: #2578a2; }
  .textmessage .textmessage-x-addinfo {
    margin-top: 32px; }
    .textmessage .textmessage-x-addinfo p {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px; }
      .textmessage .textmessage-x-addinfo p:not(:last-of-type) {
        margin-bottom: 16px; }
    .textmessage .textmessage-x-addinfo h5 {
      font-size: 16px;
      font-weight: 700;
      line-height: 24px; }
  .textmessage .textmessage-x-heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 8px; }
  @media (min-width: 768px) {
    .textmessage p {
      font-size: 24px;
      font-weight: 300;
      line-height: 32px; }
    .textmessage .textmessage-x-heading {
      font-family: "museo-slab", sans-serif;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px;
      margin-bottom: 12px; } }

.textruled {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  white-space: nowrap; }
  .textruled::after {
    background-color: rgba(73, 62, 55, 0.1);
    content: '';
    display: flex;
    flex: 1 1 100%;
    height: 2px;
    margin-left: 8px; }

.textsmall {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px; }
  .textsmall a {
    text-decoration: underline; }

.textuseraction {
  text-align: center;
  width: 100%; }
  .textuseraction .textuseraction-x-heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 24px; }
  .textuseraction p {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px; }
    .textuseraction p:last-of-type {
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
      margin-bottom: 24px; }
  @media (min-width: 768px) {
    .textuseraction .textuseraction-x-heading {
      font-family: "museo-slab", sans-serif;
      font-size: 40px;
      font-weight: 700;
      line-height: 48px; }
    .textuseraction p {
      font-size: 24px;
      font-weight: 300;
      line-height: 32px; }
      .textuseraction p:last-of-type {
        margin-bottom: 32px; } }

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  flex: 0 0 0%;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.automatedcalculation {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-left: 16px; }
  .automatedcalculation .icon {
    padding-left: 8px;
    padding-right: 4px; }
    .automatedcalculation .icon::before {
      position: relative; }
  .automatedcalculation p {
    margin-left: 4px; }

app-automated-score-calculator {
  padding: 0px !important; }

.wrap {
  background-color: #fff; }

.wrap-dark1 {
  background-color: #493e37; }

.wrap-interactive1 {
  background-color: #2578a2; }

.wrap-interactive2 {
  background-color: #004861; }

.wrap-interactive3 {
  background-color: #f7fbfd; }

.wrap-light2 {
  background-color: #f7f7f7; }

.wrap-light3 {
  background-color: #f1f2e7; }

.wrap-accent3 {
  background-color: rgba(167, 104, 37, 0.1); }

.section-group {
  display: flex;
  padding: 0px 0px 0px 24px; }

.section-header {
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 700;
  white-space: nowrap;
  background-color: #493e37;
  border-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  color: #fff;
  display: inline-block;
  padding: 8px; }

.section-level-split {
  font-size: 12px;
  line-height: 16px;
  background: linear-gradient(120deg, #493e37, #493e37 50%, #894198 50%, #894198);
  width: 25px; }

.level-header {
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 700;
  white-space: nowrap;
  background: #894198;
  border-radius: 2px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #fff;
  display: inline-block;
  padding: 8px 16px 8px 6px; }

.level-last {
  background: #368170; }

.section-level-last {
  background: linear-gradient(120deg, #493e37, #493e37 50%, #368170 50%, #368170);
  width: 25px; }

.ngx-datatable {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  box-shadow: 0 2px 0 0 #f1f2e7;
  margin-bottom: 16px;
  overflow-x: auto;
  position: relative;
  z-index: 1; }
  .ngx-datatable > div {
    min-width: 1000px; }
  .ngx-datatable .button-more {
    box-sizing: content-box; }
  .ngx-datatable .datatable-body-cell,
  .ngx-datatable .empty-row {
    background-color: #fff;
    height: initial !important;
    padding: 24px 12px; }
  .ngx-datatable .datatable-body-cell {
    display: inline-block; }
  .ngx-datatable .datatable-body-row {
    border-bottom: 1px solid #f1f2e7;
    height: initial !important; }
  .ngx-datatable .datatable-header {
    height: 51px !important; }
    .ngx-datatable .datatable-header .datatable-header-cell > div {
      display: inline-block;
      position: relative; }
    .ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-label {
      padding-right: 12px;
      position: relative; }
      .ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-label:before {
        display: none; }
      .ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-label:after {
        background-image: url("images/tablesort.svg");
        opacity: 1; }
    .ngx-datatable .datatable-header .datatable-header-cell.sort-asc .datatable-icon-up:after {
      background-image: url("images/tablesort-ascending.svg");
      content: '';
      opacity: 1;
      right: 2px; }
    .ngx-datatable .datatable-header .datatable-header-cell.sort-desc .datatable-icon-down:after {
      background-image: url("images/tablesort-descending.svg");
      content: '';
      opacity: 1;
      right: 2px; }
    .ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-label:before, .ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-label:after,
    .ngx-datatable .datatable-header .datatable-header-cell .sort-btn:before,
    .ngx-datatable .datatable-header .datatable-header-cell .sort-btn:after {
      background: center center no-repeat;
      content: '';
      display: block;
      height: 14px;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity 600ms cubic-bezier(0.19, 1, 0.22, 1);
      width: 8px; }
  .ngx-datatable .datatable-body-cell-label {
    white-space: normal !important; }
  .ngx-datatable .datatable-header-cell {
    background-color: #493e37;
    color: #fff;
    padding: 16px 12px; }
  .ngx-datatable .datatable-body-cell,
  .ngx-datatable .datatable-header-cell {
    flex: 1 1 auto; }
  .ngx-datatable .datatable-body-row,
  .ngx-datatable .datatable-header-inner,
  .ngx-datatable .datatable-row-center,
  .ngx-datatable .datatable-scroll {
    width: 100% !important; }
  .ngx-datatable .table-text-right {
    text-align: right; }
